import { useCallback, useContext } from 'react';
import { ModalContext } from '../context';
import { FORM_TYPES } from '../constants/forms';

const { CONTACT_US } = FORM_TYPES;

export const useOpenContactModal = (sourceButton, customMessage) => {
    const { openModal } = useContext(ModalContext);
    const openContactModal = useCallback(
        () => openModal({ modalType: CONTACT_US, sourceButton, customMessage }),
        [ openModal, sourceButton, customMessage ],
    );

    return openContactModal;
};
