import { useCallback, useState } from 'react';
import { subscribeToNewsletter } from '../utils/api';

export const useSubscribeToNewsletter = callback => {
    const [ state, setState ] = useState('success');

    const onSubscribeToNewsletter = useCallback(async(formValues, captchaToken) => {
        setState('loading');

        try {
            await subscribeToNewsletter(formValues, captchaToken);
            setState('success');
            callback(true);
        } catch (error) {
            console.error('onSubscribeToNewsletter error:', error);
            setState('error');
            callback(false);
        }
    }, [ callback ]);

    const resetState = useCallback(() => setState('success'), []);

    return {
        isLoading: state === 'loading',
        hasError: state === 'error',
        onSubscribeToNewsletter,
        resetState,
    };
};
