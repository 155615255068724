import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

import Link from './Link';
import styles from './styles/announcement-banner.module.scss';

const propTypes = {
    redirectTo: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    external: PropTypes.bool,
};

const AnnouncementBanner = ({ redirectTo, text, external, ...properties }) => (
    <div id={styles.banner}>
        <Link to={redirectTo} id={styles.specialBanner} external={external} {...properties}>
            <div id={styles.bannerInner}>
                <div id={styles.bannerNav}>
                    <div className={styles.bannerText}>
                        {text}
                    </div>
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        className={styles.chevronRightWhite}
                    />
                </div>
            </div>
        </Link>
    </div>
);

AnnouncementBanner.defaultProps = {
    external: false,
};
AnnouncementBanner.propTypes = propTypes;

export default AnnouncementBanner;
