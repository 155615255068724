import { useRef, useEffect } from 'react';

export const useClickOutside = (handler, isVisible) => {
    const reference = useRef();

    useEffect(() => {
        const listener = event => {
            // Trigger handler only if the component is visible
            if (!isVisible || !reference?.current || reference?.current?.contains(event.target)) {
                return;
            }
            handler(event);
        };

        document.addEventListener('click', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('click', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ isVisible, handler ]);

    return reference;
}
