import { useCallback, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { ModalContext } from '../context';
import { MODAL_TYPES, FORM_SUCCESS_MODAL_VARIANTS, FORM_REDIRECT_PARAMS } from '../constants/modal';

export const useRedirectModals = () => {
    const { openModal, closeModal } = useContext(ModalContext);
    const { search, pathname } = useLocation();

    useEffect(() => {
        if (search) {
            const parameters = new URLSearchParams(search);
            const showSuccess = parameters.get(FORM_REDIRECT_PARAMS.SUCCESS);
            const variant = parameters.get(FORM_REDIRECT_PARAMS.VARIANT);

            if (showSuccess === 'true') {
                openModal({
                    modalType: MODAL_TYPES.SUCCESS,
                    ...Object.values(FORM_SUCCESS_MODAL_VARIANTS).includes(variant)
                        && { successModalVariant: variant },
                });
            }
        }
    }, [ search, openModal ]);

    const onCloseFormSuccessModal = useCallback(() => {
        closeModal();

        // Remove the query parameter from the URL
        if (search) {
            const parameters = new URLSearchParams(search);
            parameters.delete(FORM_REDIRECT_PARAMS.SUCCESS);
            parameters.delete(FORM_REDIRECT_PARAMS.VARIANT);
            const newUrl = pathname + (parameters.toString() ? `?${parameters.toString()}` : '');
            navigate(newUrl, { replace: true });
        }
    }, [ search, pathname, closeModal ]);

    return { onCloseFormSuccessModal };
};
