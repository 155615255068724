export const GTM_KEYS = {
    HOME: {
        BANNER: 'click-home-page-banner',
        BOOK_DEMO_BUTTON: 'click-book-demo',
        VIEW_EVENTS: 'click-view-all-event',
    },
    TECHNOLOGY: {
        BOOK_DEMO: 'click-book-live-demo',
        SUPPORTED_DEVICES: 'click-supported-devices',
        WITBOX_FAMILY: 'click-meet-witbox-family',
        WITBOX_IN_ACTION: 'click-see-action',
    },
    FORM: {
        CANCEL: 'click-cancel',
        SUBMIT: 'click-submit',
        LEAVE: 'click-leave',
        CLICK_OUTSIDE: 'click-outside',
        NEWSLETTER_CHECKBOX: 'click-subscribe-newsletter',
    },
    MODAL: {
        CONTACT_US_BUTTON: 'click-contact-us-button',
        NEWSLETTER_BUTTON: 'click-subscribe-newsletter-button',
    },
};
