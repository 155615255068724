/* eslint-disable */
import React, { useCallback, useContext } from 'react';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import '@witbe/ui-color-palette';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import '../../styles/app.css';
import '../../styles/fonts.css';
import styles from './styles/layout.module.scss';
import Header from './Header';
import Footer from './Footer';
import AnnouncementBanner from './AnnouncementBanner';
import { DropdownModalContext } from '../../context';
import { useViewport } from '../../hooks';
import { GTM_KEYS } from '../../constants/googleTagManagerKeys';
import ModalController from '../layout/ModalController';
// import AggressiveModal from '../modals/AggressiveModal';

const { HOME: { BANNER } } = GTM_KEYS;

const propTypes = {
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    isEvents: PropTypes.bool,
    variant: PropTypes.oneOf(['black', 'tech-rewind-2024']),
};

const DefaultLayout = ({ data, children, bodyClass, title, isHome, isEvents, variant }) => {
    const { width } = useViewport();
    const breakpoint = 500;

    const { t } = useTranslation()
    const site = data.allGhostSettings.edges[0].node
    // react-intersection-observer
    const { ref, inView, entry } = useInView({
        /* Optional options */
        rootMargin: `-1300px`,
    });

    const [toggleDropdownModal] = useContext(DropdownModalContext);

    const html100vh = toggleDropdownModal ? styles.active : "";
    const modalOpenOverflowHidden = toggleDropdownModal ? styles.modalOpenNoScroll : "";
    const mainStyle = classNames(
        variant ? styles.mainBlack : styles.main,
        { [styles.meshGradient]: variant === 'tech-rewind-2024' },
    );

    //Other
    const flippyflip = width < breakpoint ? false : false;

    // TODO use dedicated cookies helper
    const locationCookies = useLocation();

    const setCookiesConsent = (value) => {
        const EXPIRATION = 365;
        Cookies.set("gatsby-gdpr-google-analytics", value, { expires: EXPIRATION });
        Cookies.set("gatsby-gdpr-google-tagmanager", value, { expires: EXPIRATION });
        Cookies.set("gatsby-gdpr-hotjar", value, { expires: EXPIRATION });
        initializeAndTrack(locationCookies);
    }

    const acceptCookies = useCallback(() => {
        setCookiesConsent('true');
    }, []);

    const declineCookies = useCallback(() => {
        setCookiesConsent('false');
    }, []);

    return (
        <>
            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet" />
                <html lang='en' className={html100vh} />
                <meta name="google-site-verification" content="i2DX0A8JUQNdJzQmrE27GvJ96xktsgfGWhgJLjkUquM" />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
                <title>{title}</title>
            </Helmet>

            <CookieConsent
                location="bottom"
                buttonText="Accept all"
                declineButtonText="Reject all"
                buttonClasses="cookieacceptbutton"
                declineButtonClasses="cookiedeclinebutton"
                cookieName="gatsby-gdpr-google-analytics"
                style={{ background: "#F2F2F2" }}
                expires={365}
                containerClasses="witbecookieswrapper"
                contentClasses="witbecookies"
                buttonWrapperClasses="buttonwrapper"
                enableDeclineButton
                flipButtons={flippyflip}
                onAccept={acceptCookies}
                onDecline={declineCookies}
            >
                <div
                    // The user has to accept or decline the cookies to click elsewhere on the website,
                    // this div is catching the clicks behind the cookies pop-up
                    className={styles.absorbClick}
                />
                <span>We think life is better with cookies, but the choice is yours!</span>
                We use cookies on our website to improve and analyze your browsing experience. By clicking “Accept all,” you're giving us permission to use cookies for these reasons. Of course, you can always choose not to accept. To learn more about how we use cookies and how you can manage them, please see our <a href='https://www.witbe.net/policy/'>Privacy Policy</a>. {" "}
            </CookieConsent>

            <ModalController />

            {/* <AggressiveModal /> */}

            <div className={modalOpenOverflowHidden}>
                <Header isHome={isHome} isDarkMode={!!variant} />
                {isHome && <AnnouncementBanner
                    redirectTo='/events/nab-show-las-vegas-2025/'
                    text="Meet us at NAB Show 2025! April 5-9 - Book a meeting"
                    data-gtm-id={BANNER}
                />}
                <main className={mainStyle} ref={ref}>
                    {/* All the main content gets inserted here, index.js, post.js */}
                    {children}
                </main>
                <Footer isEvents={isEvents} isDarkMode={!!variant} />
                <div className="frc-captcha" data-sitekey="FCMUUIBJHJ1KPM4"></div>
            </div>
        </>
    )
}

DefaultLayout.propTypes = propTypes;

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
