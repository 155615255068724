import { SALESFORCE_FIELDS_NAME } from '../constants';

const { INDUSTRY, COMPANY } = SALESFORCE_FIELDS_NAME;

export const getApiUrl = () => process.env.API_URL || '/send';

export const getApiEndpoint = endpoint => `${getApiUrl()}${endpoint}`;

export const sendSmartgateReport = async(email, captchaToken) => {
    const response = await fetch(getApiEndpoint('/tech-rewind/smartgate-report'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, 'g-recaptcha-response': captchaToken }),
    });
    if (!response.ok) {
        throw new Error(`Can't send smartgate report: ${response.status}`);
    }

    return response?.json() || {};
};

export const subscribeToNewsletter = async(
    {
        first_name: firstName,
        last_name: lastName,
        email,
        country_code: country,
        [INDUSTRY]: industry,
        acknowledgement,
    },
    captchaToken,
) => {
    const response = await fetch(getApiEndpoint('/form/newsletter'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            country,
            industry,
            acknowledgement,
            'g-recaptcha-response': captchaToken
        }),
    });
    if (!response.ok) {
        console.log('subscribeToNewsletter error:', response);
        throw new Error(`Can't subscribe user to the newsletter: ${response.status}`);
    }

    return response?.json() || {};
};
