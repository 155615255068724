export const MODAL_TYPES = {
    CONTACT_US: 'contact-us',
    NEWSLETTER: 'newsletter',
    SUCCESS: 'success',
}

export const MODAL_SOURCE_BUTTONS = {
    ABOUT_COMPANY: 'about-company',
    BLOG_SIDEBAR: 'blog-sidebar',
    BLUE_BOX: 'blue-box',
    BOOK_DEMO_HOME: 'home-book-demo',
    BOOK_DEMO_TECHNOLOGY: 'technology-book-demo',
    EVENTS: 'events',
    FOOTER: 'footer',
    GET_STARTED_FOOTER: 'get-started-footer',
    HEADER: 'header',
    LOCATIONS: 'locations',
    QA_TEST_AUTOMATION: 'qa-test-automation',
    REMOTE_DEVICE_ACCESS: 'remote-device-access',
    SITEMAP: 'sitemap',
    SUPERBOWL_25: 'superbowl-2025',
    SUPPORTED_DEVICES: 'supported-devices',
    TECH_REWIND_23: 'tech-rewind-2023',
    TECH_REWIND_24: {
        MANUAL: {
            BUILD: 'tech-rewind-2024-manual-build',
            RECORD: 'tech-rewind-2024-manual-record',
        },
        AUTOMATED: {
            TRY: 'tech-rewind-2024-automated-try',
            REPORT: 'tech-rewind-2024-automated-report',
        },
        SCALE: {
            PAIR: 'tech-rewind-2024-pair',
            AI: 'tech-rewind-2024-ai',
        },
        INDEPENDENTLY: {
            CERTIFICATION: 'tech-rewind-2024-independently-certification',
            NEWSLETTER: 'tech-rewind-2024-independently-newsletter',
        },
        CELEBRATION: 'tech-rewind-2024-celebration',
    },
    VIDEO_SERVICE_MONITORING: 'video-service-monitoring',
    VOD_ASSET_CHECKING: 'vod-asset-checking',
    WITBOX: 'witbox',
};

export const FORM_SUCCESS_MODAL_VARIANTS = {
    DEFAULT: 'default',
    YOUR_IN: 'your_in',
    ERROR_NEWSLETTER: 'error-newsletter',
};

export const FORM_REDIRECT_PARAMS = {
    SUCCESS: 'formRedirectSuccess',
    VARIANT: 'modalVariant',
};
