import { useState, useEffect, useRef } from 'react';

export const useGenericModalAnimation = (isOpen, onAnimationEnd) => {
    const [ isClosing, setIsClosing ] = useState(false);
    const shouldAnimateClose = useRef(false);
    const isClosed = !isClosing && !isOpen;

    useEffect(() => {
        if (!isOpen && shouldAnimateClose.current) {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                onAnimationEnd();
            }, 400);
        }

        if (isOpen) {
            shouldAnimateClose.current = true;
        }
    }, [ isOpen, onAnimationEnd ]);

    return { isClosing, isClosed };
};
