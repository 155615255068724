import { useCallback, useState } from 'react';
import { sendSmartgateReport } from '../../utils/api';

export const useSendReport = callback => {
    const [ state, setState ] = useState('success');

    const onSendReport = useCallback(async(email, captchaToken) => {
        setState('loading');

        try {
            await sendSmartgateReport(email, captchaToken);
            setState('success');
            callback();
        } catch (error) {
            console.error(error);
            setState('error');
        }
    }, [ callback ]);

    const resetState = useCallback(() => setState('success'), []);

    return {
        isLoading: state === 'loading',
        hasError: state === 'error',
        onSendReport,
        resetState,
    };
};
