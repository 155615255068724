/* eslint-disable react-hooks/exhaustive-deps*/
import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useCarousel = () => {
    const carouselImages = useStaticQuery(graphql `
        query CarouselImagesHookDesktop {
            qaBanner: file(relativePath : { eq: "index/2024/qa-featured.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1800
                    )
                }
            },
            qaThumb: file(relativePath : { eq: "index/2023/qa-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            qaMobile: file(relativePath : { eq: "index/2023/qa-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            vsmBanner: file(relativePath : { eq: "index/2024/vsm-featured.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1800
                    )
                }
            },
            vsmThumb: file(relativePath : { eq: "index/2023/vsm-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            vsmMobile: file(relativePath : { eq: "index/2023/vsm-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            rdaBanner: file(relativePath : { eq: "index/2024/rda-featured.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1800
                    )
                }
            },
            rdaThumb: file(relativePath : { eq: "index/2023/rda-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            rdaMobile: file(relativePath : { eq: "index/2023/rda-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            vodBanner: file(relativePath : { eq: "index/2024/vod-featured.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1800
                    )
                }
            },
            vodThumb: file(relativePath : { eq: "index/2023/vod-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            vodMobile: file(relativePath : { eq: "index/2023/vod-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            adBanner: file(relativePath : { eq: "index/2024/am-featured.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1800
                    )
                }
            },
            adThumb: file(relativePath : { eq: "index/2023/ad-monitoring-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            adMobile: file(relativePath : { eq: "index/2023/ad-monitoring-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            }
        }
    `);

    const carouselDataArray = useMemo(() => [
        {
            itemId: 4,
            link: '/technology/video-service-monitoring/',
            image: carouselImages.qaThumb.childImageSharp.gatsbyImageData,
            name: 'Video service monitoring',
            title: 'Video service monitoring',
            subtitle: 'Monitor live and VoD performance on real devices! Receive immediate alerts and access video traces when errors strike.',
            description: 'Monitor live and on demand video quality with Witbe’s Video Service Monitoring technology! Receive immediate alerts whenever service errors affect real devices. Plus, collaborate to reduce response time with video traces of the error!',
            alt: 'Video service monitoring',
            bannerImage: carouselImages.vsmBanner.childImageSharp.gatsbyImageData,
            thumbImage: carouselImages.vsmThumb.childImageSharp.gatsbyImageData,
            mobileImage: carouselImages.vsmMobile.childImageSharp.gatsbyImageData
        },
        {
            itemId: 2,
            link: '/technology/remote-device-access/',
            image: carouselImages.qaThumb.childImageSharp.gatsbyImageData,
            name: 'Remote device control',
            title: 'Remote device control',
            subtitle: 'Access your real testing devices from anywhere in the world! Reduce truck roll by confirming errors remotely.',
            description: 'Control real testing devices from anywhere in the world with Witbe’s Remote Device Access technology! Monitor live performance from any web browser, including on smartphones and tablets. Plus, reduce truck roll by confirming errors remotely!',
            alt: 'Remote device control',
            bannerImage: carouselImages.rdaBanner.childImageSharp.gatsbyImageData,
            thumbImage: carouselImages.rdaThumb.childImageSharp.gatsbyImageData,
            mobileImage: carouselImages.rdaMobile.childImageSharp.gatsbyImageData
        },
        {
            itemId: 3,
            link: '/technology/qa-test-automation/',
            image: carouselImages.qaThumb.childImageSharp.gatsbyImageData,
            name: 'QA test automation',
            title: 'QA test automation',
            subtitle: 'Automate your endurance, stress, and performance testing! Run tests 24 hours a day without missing a beat.',
            description: 'Run tests 24/7 with Witbe’s QA Test Automation technology! Automate repetitive tasks and cover the endurance, stress, and performance testing that can’t be done manually. Plus, script faster than ever before with AI algorithms!',
            alt: 'QA test automation',
            bannerImage: carouselImages.qaBanner.childImageSharp.gatsbyImageData,
            thumbImage: carouselImages.qaThumb.childImageSharp.gatsbyImageData,
            mobileImage: carouselImages.qaMobile.childImageSharp.gatsbyImageData
        },
        {
            itemId: 1,
            link: '/articles/witbe-ad-monitoring-content-matching-best-video-advertising-solution-2023/',
            image: carouselImages.qaThumb.childImageSharp.gatsbyImageData,
            name: 'Ad monitoring and matching',
            title: 'Ad monitoring and matching',
            subtitle: 'Measure true ad streaming quality on real devices! Verify errors caused by Dynamic Ad Insertion.',
            description: 'Measure true ad streaming quality with Witbe’s Ad Monitoring and Matching technology! Monitor dynamically inserted ads on real devices and networks to verify performance. Plus, secure advertiser revenue with recordings!',
            bannerImage: carouselImages.adBanner.childImageSharp.gatsbyImageData,
            thumbImage: carouselImages.adThumb.childImageSharp.gatsbyImageData,
            mobileImage: carouselImages.adMobile.childImageSharp.gatsbyImageData
        },
        {
            itemId: 5,
            link: '/technology/vod-asset-checking/',
            image: carouselImages.qaThumb.childImageSharp.gatsbyImageData,
            name: 'VoD asset checking',
            title: 'VoD asset checking',
            subtitle: 'Monitor content across different platforms! Verify that assets are playing correctly with subtitles and more.',
            description: 'Monitor content across platforms with Witbe’s VoD Asset Checking technology! Verify that assets are playing with the correct subtitles, language options, and more. Plus, test quality across different devices and networks!',
            alt: 'VoD asset checking',
            bannerImage: carouselImages.vodBanner.childImageSharp.gatsbyImageData,
            thumbImage: carouselImages.vodThumb.childImageSharp.gatsbyImageData,
            mobileImage: carouselImages.vodMobile.childImageSharp.gatsbyImageData
        }
    ], [ carouselImages ]);

    return carouselDataArray;
};
