import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { navigate, Link } from 'gatsby';

import { ModalContext } from '../../context';
import { FORM_SUCCESS_MODAL_VARIANTS } from '../../constants/modal';
import successLogo from '../../images/logos/logo3.png';

import styles from '../common/styles/formFeedback.module.scss';

const {
    DEFAULT,
    YOUR_IN,
    ERROR_NEWSLETTER,
} = FORM_SUCCESS_MODAL_VARIANTS;

const propTypes = {
    variant: PropTypes.oneOf(Object.values(FORM_SUCCESS_MODAL_VARIANTS)),
};

const FormFeedback = React.memo(({ variant }) => {
    const { closeModal } = useContext(ModalContext);

    const onArticleClick = useCallback((e) => {
        e.preventDefault();

        closeModal();
        setTimeout(() => navigate('/articles'), 400); // Wait for the modal closing animation to end before navigating
    }, [ closeModal ])

    let title;
    let content;

    switch (variant) {
        case ERROR_NEWSLETTER:
            title = "Oops!";
            content = <p>Something went wrong, and we couldn’t process your sign-up. Please try again later, or contact us if the issue persists - <a href='mailto:hello@witbe.net'>hello@witbe.net</a></p>;
            break;
        case YOUR_IN:
            title = 'You\'re in!';
            content = (
                <p>
                    {'You will now receive exclusive Witbe updates and insights. In the meantime, explore '}
                    <Link to='/articles' onClick={onArticleClick}>our latest blog posts</Link>
                    .
                </p>
            );
            break;
        case DEFAULT:
        default:
            title = 'Thank you!';
            content = <p>Your message has been successfully received. We will get back to you shortly.</p>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.animateWrapper}>
                <img src={successLogo} alt='Thank you!' />
            </div>
            <h3 className={styles.aboutYouH3}>
                {title}
            </h3>
            <div className={styles.content}>
                {content}
            </div>
        </div>
    );
});

FormFeedback.displayName = 'FormFeedback';
FormFeedback.propTypes = propTypes;
FormFeedback.defaultProps = {
    variant: 'default',
};

export default FormFeedback;
