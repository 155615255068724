import { useCallback, useState } from 'react';

export const useFormErrors = () => {
    const [ errors, setErrors ] = useState({});
    const hasError = Object.keys(errors).length > 0;

    const addError = useCallback((fieldKey, errorMessage) => {
        setErrors(previous => ({
            ...previous,
            [ fieldKey ]: errorMessage
        }));
    }, []);

    const removeError = useCallback(fieldKey => {
        setErrors(previous => {
            const copy = { ...previous };
            delete copy[ fieldKey ];
            return copy;
        });
    }, []);

    const resetErrors = useCallback(() => {
        setErrors({});
    }, []);

    return {
        errors,
        hasError,
        addError,
        removeError,
        resetErrors,
    };
};
