import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../common/styles/salesforce.module.scss';

const propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
};

const defaultProps = {
    defaultValue: null,
};

const FormTextArea = ({ id, label, defaultValue }) => (
    <div className={styles.customInput}>
        <label htmlFor={id}>{label}</label>
        <textarea
            id={id}
            name={id}
            className={styles.inputStyle}
            rows='10'
            type='text'
            wrap='soft'
            defaultValue={defaultValue}
        />
    </div>
);

FormTextArea.propTypes = propTypes;
FormTextArea.defaultProps = defaultProps;

export default FormTextArea;
