import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import styles from './styles/formCaptcha.module.scss';
import { CAPTCHA_PUBLIC_KEY, CAPTCHA_PUBLIC_KEY_DEBUG, SALESFORCE_ORG_ID } from '../../../constants/forms';

const propTypes = {
    onLoaded: PropTypes.func.isRequired,
    isDebug: PropTypes.bool,
};

const defaultProps = {
    isDebug: false,
};

// "WitbeNetCaptcha" captcha key will be loaded in SalesForce.
// Makes sure that this key exists and corresponds to CAPTCHA_PUBLIC_KEY value.
// https://medium.com/@terrystidbits2019/using-recaptcha-on-salesforce-web-to-lead-forms-81f1fd22dd06
const DEFAULT_VALUE = {
    keyname: 'WitbeNetCaptcha',
    fallback: 'true',
    orgId: SALESFORCE_ORG_ID,
    ts: '',
};

// This component must be inside the saleforce form tag
const FormCaptcha = ({ onLoaded, isDebug }) => {
    const [ captchaValue, setCaptchaValue ] = useState(DEFAULT_VALUE);
    const inputValue = JSON.stringify(captchaValue);

    const onHandleChange = useCallback(token => {
        setCaptchaValue(previousState => ({
            ...previousState,
            ts: Date.now().toString()
        }));
        onLoaded(token);
    }, [ onLoaded ]);

    return (
        <React.Fragment>
            <input type='hidden' name='captcha_settings' value={inputValue} />
            <ReCAPTCHA
                className={styles.captcha}
                sitekey={!isDebug ? CAPTCHA_PUBLIC_KEY : CAPTCHA_PUBLIC_KEY_DEBUG}
                onChange={onHandleChange}
            />
        </React.Fragment>
    );
};

FormCaptcha.propTypes = propTypes;
FormCaptcha.defaultProps = defaultProps;

export default FormCaptcha;
