import { useArticlesImage } from './useArticlesImage';

import articlesData from '../content/articles.json';

export const useArticles = () => {
    const images = useArticlesImage();

    // @FIXME: We should refactor how we handle pictures for highlighted articles
    // For now we're using public images without any optimizations with GatsbyImage.
    const articlesImagesArray = [
        {
            link: '/articles/lightwave-btr-innovation-reviews-2025-4-diamonds-virtual-noc/',
            image: images.lightwaveBtrAwardSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.lightwaveBtrAwardMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.lightwaveBtrAwardFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/new-nctc-member/',
            image: images.nctcPrSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.nctcPrMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.nctcPrFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/automated-latency-monitoring-superbowl-2025/',
            image: images.superbowl2025Square.childImageSharp.gatsbyImageData,
            mobileImage: images.superbowl2025Mobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.superbowl2025FooterList.childImageSharp.gatsbyImageData,
            bannerImage: images.superbowl2025Banner.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/tag-video-system-partnership-end-to-end-video-monitoring-press-release/',
            image: images.tagPrSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.tagPrMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.tagPrFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/digital-media-world-awards-2024/',
            image: images.dmwSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.dmwMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.dmwFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/transform-qa-workflows-lab-testing-to-field-testing/',
            image: images.qaVNocSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.qaVNocMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.qaVNocFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/people-choice-feed-honours-award-marie-veronique-lacaze/',
            image: images.feedHonoursSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.feedHonoursMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.feedHonoursFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/tech-rewind-2024/',
            image: images.techRewind24Square.childImageSharp.gatsbyImageData,
            mobileImage: images.techRewind24Mobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.techRewind24FooterList.childImageSharp.gatsbyImageData,
            featuredOnHomeCarousel: true,
            imageHomeCarouselDesktop: '/articles/tech-rewind-2024/tech-rewind-2024-home-highlight-desktop.jpg',
            imageHomeCarouselMobile: '/articles/tech-rewind-2024/tech-rewind-2024-home-highlight-mobile.jpg'
        },
        {
            link: '/articles/new-dtg-member-video-monitoring-uk-providers/',
            image: images.dtgPrSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.dtgPrMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.dtgPrFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/benefits-of-virtual-noc/',
            image: images.nocBenefitsSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.nocBenefitsMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.nocBenefitsFooterList.childImageSharp.gatsbyImageData,
            imageHomeCarouselDesktop: '/articles/2024-12-09-noc-benefits/PM_asset-home-highlight.jpg',
            imageHomeCarouselMobile: '/articles/2024-12-09-noc-benefits/PM_asset-home-landscape.jpg',
        },
        {
            link: '/articles/what-is-a-virtual-noc/',
            image: images.nocNovemberSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.nocNovemberMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.nocNovemberBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.nocNovemberFooterList.childImageSharp.gatsbyImageData,
            featuredOnHomeCarousel: true,
            imageHomeCarouselDesktop: '/articles/2024-11-04-noc/noc_asset-home-highlight-desktop.jpg',
            imageHomeCarouselMobile: '/articles/2024-11-04-noc/noc_asset-home-highlight-mobile.jpg',
        },
        {
            link: '/articles/msctv-partnership-virtual-noc-live-monitoring/',
            image: images.msctvSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.msctvMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.msctvBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.msctvFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/dubai-new-office/',
            image: images.dubaiNewOfficeSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.dubaiNewOfficeMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.dubaiNewOfficeBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.dubaiNewOfficeFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/bouygues-telecom-upgrades-automated-testing-capabilities-with-witbox/',
            image: images.bouyguesSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.bouyguesMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.bouyguesBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.bouyguesFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/best-of-show-award-ibc-mobile-automation-2024/',
            image: images.bsAwardSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.bsAwardMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.bsAwardBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.bsAwardFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/csi-award-ad-monitoring-2024/',
            image: images.csiAwardSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.csiAwardMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.csiAwardBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.csiAwardFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/dtr-award-ad-monitoring-2024/',
            image: images.dtrSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.dtrMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.dtrBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.dtrFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/new-virtual-noc-launch-press-release/',
            image: images.nocSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.nocMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.nocBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.nocFooterList.childImageSharp.gatsbyImageData,
            imageHomeCarouselDesktop: '/articles/2024-10-02-noc/noc-asset-home-highlight.jpg',
            imageHomeCarouselMobile: '/articles/2024-10-02-noc/noc-asset-articles-content-landscape.jpg',
        },
        {
            link: '/articles/paul-bunyan-communications-witbe-live-video-monitoring-technology/',
            image: images.paulBunyanSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.paulBunyanMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.paulBunyanBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.paulBunyanFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/ad-monitoring-nab-product-of-the-year-award-2024/',
            image: images.nabpotySquare.childImageSharp.gatsbyImageData,
            mobileImage: images.nabpotyMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.nabpotyBannerHero.childImageSharp.gatsbyImageData,
            imageFooterList: images.nabpotyFooterList.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/smart-navigate-nab-best-of-show-award-2024/',
            image: images.nabbosSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.nabbosMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.nabbosBannerHero.childImageSharp.gatsbyImageData,
            imageFooterList: images.nabbosFooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/abr-stream-analysis/',
            image: images.abrSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.abrMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.abrBannerHero.childImageSharp.gatsbyImageData,
            imageFooterList: images.abrFooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/channel-4-partnership-remote-eye-controller-witbox/',
            image: images.channel4Square.childImageSharp.gatsbyImageData,
            mobileImage: images.channel4Mobile.childImageSharp.gatsbyImageData,
            bannerImage: images.channel4BannerHero.childImageSharp.gatsbyImageData,
            imageFooterList: images.channel4FooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/tech-rewind-2023/',
            image: images.techrewind2023Square.childImageSharp.gatsbyImageData,
            mobileImage: images.techrewind2023Mobile.childImageSharp.gatsbyImageData,
            bannerImage: images.techrewind2023Banner.childImageSharp.gatsbyImageData,
            imageFooterList: images.techrewind2023FooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/witbes-rec-monitoring-tool-and-analysis-tool-wins-4-diamonds/',
            image: images.btr2023Square.childImageSharp.gatsbyImageData,
            mobileImage: images.btr2023Mobile.childImageSharp.gatsbyImageData,
            bannerImage: images.btr2023Banner.childImageSharp.gatsbyImageData,
            imageFooterList: images.btr2023FooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/witbe-ad-monitoring-content-matching-best-video-advertising-solution-2023/',
            image: images.adSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.adMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.adBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.adFooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/smart-navigate-ai-test-automation-for-video-service-providers/',
            image: images.smartnavSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.smartnavMobile.childImageSharp.gatsbyImageData,
            bannerImage: images.smartnavBanner.childImageSharp.gatsbyImageData,
            imageFooterList: images.smartnavFooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/fast-channel-ad-insertion-monitoring-strategies/',
            image: images.fastSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.fastMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.fastFooterList.childImageSharp.gatsbyImageData
        },

        /* Ghost articles */
        {
            link: '/articles/witbe-witbox-wins-2023-nab-show-product-of-the-year-award/',
            image: images.nabSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.nabMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.nabFooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/witbe-montreal-office-relocates-to-support-canadian-video-service-providers/',
            image: images.montrealSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.montrealMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.montrealFooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/short-form-video-quality-testing/',
            image: images.shortformSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.shortformMobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.shortformFooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/t-mobile-nl-automated-qa-testing-partnership/',
            image: images.tmobileSquare.childImageSharp.gatsbyImageData,
            mobileImage: images.tmobileMobile.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/witbe-cloud-network-and-apac-headquarters/',
            image: images.singapore.childImageSharp.gatsbyImageData,
            mobileImage: images.singaporeMobile.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/witbox-named-best-in-market/',
            image: images.best2022.childImageSharp.gatsbyImageData,
            mobileImage: images.best2022Mobile.childImageSharp.gatsbyImageData,
            imageFooterList: images.best2022FooterList.childImageSharp.gatsbyImageData
        },
        {
            link: '/articles/witbe-partners-with-mytv-super-limited/',
            image: images.tvb.childImageSharp.gatsbyImageData,
            mobileImage: images.tvbMobile.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/btr-recommends-for-testing-and-monitoring-device/',
            image: images.btr.childImageSharp.gatsbyImageData,
            mobileImage: images.btr.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/test-automation-for-united-cloud/',
            image: images.unitedCloud.childImageSharp.gatsbyImageData,
            mobileImage: images.unitedCloud.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/new-witbox-launch-for-scalable-remote-control-and-test-automation/',
            image: images.witboxPlus.childImageSharp.gatsbyImageData,
            mobileImage: images.witboxPlus.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/witbox-the-best-slingbox-alternative/',
            image: images.witboxhand.childImageSharp.gatsbyImageData,
            mobileImage: images.witboxhand.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/the-perfect-replacement-to-stormtest/',
            image: images.stormtest.childImageSharp.gatsbyImageData,
            mobileImage: images.stormtest.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/meet-the-witbox/',
            image: images.witbox.childImageSharp.gatsbyImageData,
            mobileImage: images.witbox.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/video-quality-monitoring-of-a-live-event-nfl-draft-2021/',
            image: images.nfl.childImageSharp.gatsbyImageData,
            mobileImage: images.nfl.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/why-is-android-tv-test-automation-so-important-in-2022/',
            image: images.android.childImageSharp.gatsbyImageData,
            mobileImage: images.android.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/conversation-with-mr-trecourt-director-of-network-and-telecoms-at-sodexo/',
            image: images.sodexowitbe.childImageSharp.gatsbyImageData,
            mobileImage: images.sodexowitbe.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/the-smallest-robot-for-remote-control-and-automated-testing/',
            image: images.witboxIllo.childImageSharp.gatsbyImageData,
            mobileImage: images.witboxIllo.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/the-future-of-ott-monitoring-witbe-cloud-ondemand/',
            image: images.cloud.childImageSharp.gatsbyImageData,
            mobileImage: images.cloud.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/the-perfect-slingbox-replacement-for-any-noc-and-qa-teams/',
            image: images.slingboxBlog.childImageSharp.gatsbyImageData,
            mobileImage: images.slingboxBlog.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/an-offer-designed-for-msos-and-cable-operators/',
            image: images.MegaHertz.childImageSharp.gatsbyImageData,
            mobileImage: images.MegaHertz.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/importance-of-quality-of-experience/',
            image: images.qoe.childImageSharp.gatsbyImageData,
            mobileImage: images.qoe.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/the-importance-of-qoe-with-cloud-gaming/',
            image: images.cloudGaming.childImageSharp.gatsbyImageData,
            mobileImage: images.cloudGaming.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/the-first-automated-technology-to-monitor-eas-warnings/',
            image: images.eas.childImageSharp.gatsbyImageData,
            mobileImage: images.eas.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/new-logo-new-website-new-witbe/',
            image: images.newLogo.childImageSharp.gatsbyImageData,
            mobileImage: images.newLogo.childImageSharp.gatsbyImageData,
        },

        /* JS articles */
        {
            link: '/articles/how-to-measure-end-to-end-latency-over-5g/',
            image: images.latency5g.childImageSharp.gatsbyImageData,
            mobileImage: images.latency5g.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/ivr-and-call-centers-monitoring/',
            image: images.ivr.childImageSharp.gatsbyImageData,
            mobileImage: images.ivr.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/how-sodexo-ensures-good-quality-for-their-internal-services/',
            image: images.sodexo.childImageSharp.gatsbyImageData,
            mobileImage: images.sodexo.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/rec-use-cases/',
            image: images.RECUseCase.childImageSharp.gatsbyImageData,
            mobileImage: images.RECUseCase.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/automated-test-for-smart-tv-services/',
            image: images.smartTV.childImageSharp.gatsbyImageData,
            mobileImage: images.smartTV.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/deep-dive-into-video-conferencing-world/',
            image: images.videoConferencing.childImageSharp.gatsbyImageData,
            mobileImage: images.videoConferencing.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/ott-video-services-monitoring/',
            image: images.screenshot1.childImageSharp.gatsbyImageData,
            mobileImage: images.screenshot1.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/multiscreen-and-multidevices-video-monitoring/',
            image: images.screenshot2.childImageSharp.gatsbyImageData,
            mobileImage: images.screenshot2.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/voice-services-monitoring/',
            image: images.voiceServices.childImageSharp.gatsbyImageData,
            mobileImage: images.voiceServices.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/mobile-virtual-network-operator-qoe-monitoring/',
            image: images.mvnoQoe.childImageSharp.gatsbyImageData,
            mobileImage: images.mvnoQoe.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/tv-iptv-and-vod-services-monitoring/',
            image: images.screenshot5.childImageSharp.gatsbyImageData,
            mobileImage: images.screenshot5.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/broadband-voice-and-tv-tripleplay-monitoring/',
            image: images.screenshot6.childImageSharp.gatsbyImageData,
            mobileImage: images.screenshot6.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/unified-communications-qoe-monitoring/',
            image: images.unified.childImageSharp.gatsbyImageData,
            mobileImage: images.unified.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/business-applications-performance-monitoring/',
            image: images.businessApps.childImageSharp.gatsbyImageData,
            mobileImage: images.businessApps.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/settop-box-test-automation-and-validation/',
            image: images.screenshot10.childImageSharp.gatsbyImageData,
            mobileImage: images.screenshot10.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/web-and-smartphone-applications-monitoring/',
            image: images.screenshot11.childImageSharp.gatsbyImageData,
            mobileImage: images.screenshot11.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/vod-asset-checking-and-monitoring/',
            image: images.screenshot12.childImageSharp.gatsbyImageData,
            mobileImage: images.screenshot12.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/google-cloud-gaming-quality-analysis/',
            image: images.snapshot7Picture1.childImageSharp.gatsbyImageData,
            mobileImage: images.snapshot7Picture1.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/does-5g-fulfill-its-promises/',
            image: images.fiveGBanner.childImageSharp.gatsbyImageData,
            mobileImage: images.fiveGBanner.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/best-mobile-operator-for-video-apps-in-canada/',
            image: images.snapshot6Picture1.childImageSharp.gatsbyImageData,
            mobileImage: images.snapshot6Picture1.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/best-neighborhood-in-london-to-watch-mobile-videos/',
            image: images.londonBanner.childImageSharp.gatsbyImageData,
            mobileImage: images.londonBanner.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/best-way-to-watch-superbowl/',
            image: images.superbowlBanner.childImageSharp.gatsbyImageData,
            mobileImage: images.superbowlBanner.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/best-ott-app-on-apple-tv-in-us/',
            image: images.appleBanner.childImageSharp.gatsbyImageData,
            mobileImage: images.appleBanner.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/best-ott-video-mobile-app-in-uk/',
            image: images.OTTUkBanner.childImageSharp.gatsbyImageData,
            mobileImage: images.OTTUkBanner.childImageSharp.gatsbyImageData,
        },
        {
            link: '/articles/best-video-operator-in-north-america-and-europe/',
            image: images.snapshot3Picture5.childImageSharp.gatsbyImageData,
            mobileImage: images.snapshot3Picture5.childImageSharp.gatsbyImageData,
        }
    ];

    return articlesData.map(article => {
        const {
            postId,
            link,
            titleList,
            subtitleList,
            tags,
            topics,
            keywordsSearch,
            industries,
            thumbnailAlt,
            featuredOnBlog,
            featuredOnHomeCarousel,
        } = article;

        const articleImages = articlesImagesArray.find(item => item.link === link);

        return {
            postId,
            link,
            titleList,
            subtitleList,
            tags,
            topics,
            keywordsSearch,
            industries,
            thumbnailAlt,
            featuredOnBlog,
            featuredOnHomeCarousel,
            ...articleImages,
        };
    });
};
