import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

const propTypes = {
    to: PropTypes.string,
    children: PropTypes.node.isRequired,
    ref: PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]),
    external: PropTypes.bool,
};

const Link = React.memo(({ to, ref, external, children, ...rest }) => {
    // We should use anchor for external links
    // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links
    if (external) {
        return <a {...rest} href={to} ref={ref}>{children}</a>;
    }

    return <GatsbyLink {...rest} ref={ref} to={to}>{children}</GatsbyLink>;
});

Link.propTypes = propTypes;
Link.defaultProps = {
    to: null,
    ref: null,
    external: false,
};
Link.displayName = 'Link';

export default Link;
