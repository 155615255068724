import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import Link from './Link';
import WitbeLogo from '../../../static/assets/svgs/witbe_logo_color_gradient_2023.svg';
import Hamburger from '../../../static/assets/svgs/hamburger_icon.svg';
import WitbeAppLogo from '../../../static/assets/svgs/log_in_icon.svg';
import headStyle from './styles/newHeader.module.scss';
import { useOpenContactModal } from '../../hooks';
import { GTM_KEYS, MODAL_SOURCE_BUTTONS } from '../../constants';

const { MODAL: { CONTACT_US_BUTTON } } = GTM_KEYS;
const { HEADER } = MODAL_SOURCE_BUTTONS;

const propTypes = {
    isDarkMode: PropTypes.bool,
}
const defaultProps = {
    isDarkMode: false,
}

const Header = ({ isDarkMode }) => {
    const { t } = useTranslation();
    const [ menuOpen, setMenuOpen ] = useState(false);
    const onToggleMenu = useCallback(() => {
        setMenuOpen(!menuOpen);
    }, [ menuOpen ]);

    const openContactModal = useOpenContactModal(HEADER);

    const buttonStyles = {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: 16,
        fontWeight: 'bold',
        color: isDarkMode ? 'var(--grey-50)' : 'white',
        background: '#286CEB',
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 8,
        paddingTop: 8,
        borderRadius: '2em'
    };

    return (

        <header id={headStyle.header} className={classNames({
            [headStyle.headerDarkMode]: isDarkMode,
            [headStyle.headerOpen]: menuOpen,
        })}>
            <div id={headStyle.headerInner}>
                <button id={headStyle.headerBurger} onClick={onToggleMenu} aria-label='Hamburger Menu Toggle' type='button'>
                    <Hamburger />
                </button>
                <nav id={headStyle.headerNav} aria-label={t('siteMetadata.navigation.primaryLabel')}>
                    <Link id={headStyle.headerLogo} to='/' aria-label='Witbe Homepage'>
                        <WitbeLogo />
                    </Link>
                    <Link to='/technology/' className={headStyle.headerLinkTechnology}>
                        <p>{t('siteMetadata.header.navigationLinks.technology')}</p>
                    </Link>
                    <Link to='/articles/' className={headStyle.headerLinkArticles}>
                        <p>{t('siteMetadata.header.navigationLinks.articles')}</p>
                    </Link>
                    <button
                        style={buttonStyles}
                        className={headStyle.blueButton}
                        onClick={openContactModal}
                        type='button'
                        data-gtm-id={`${CONTACT_US_BUTTON}-${HEADER}`}
                    >
                        {t('siteMetadata.header.navigationLinks.contact')}
                    </button>
                </nav>
                <nav id={headStyle.headerNavRight}>
                    <a href='https://witbe.app/' className={headStyle.headerLinkLogin + ' ' + headStyle.login}>Log in</a>
                    <a href='https://witbe.app/' id={headStyle.headerLogoApp}>
                        <WitbeAppLogo />
                    </a>
                </nav>
                <div id={headStyle.headerMobileMenu}>
                    <div id={headStyle.headerMobileLinks}>
                        <Link to='/technology/' className={headStyle.headerMobileLink} onClick={onToggleMenu}>
                            {t('siteMetadata.header.navigationLinks.technology')}
                        </Link>
                        <Link to='/articles/' className={headStyle.headerMobileLink} onClick={onToggleMenu}>
                            {t('siteMetadata.header.navigationLinks.articles')}
                        </Link>
                        <Link
                            to='#'
                            className={`${headStyle.headerMobileLink} ${headStyle.requestADemoMobile}`}
                            onClick={openContactModal}
                            data-gtm-id={`${CONTACT_US_BUTTON}-${HEADER}`}
                        >
                            Contact us
                        </Link>
                        <a href='https://witbe.app/' className={headStyle.headerMobileLink} onClick={onToggleMenu}>Log in</a>
                    </div>
                    <button id={headStyle.headerMobileClose} onClick={onToggleMenu} aria-label='Hamburger Menu Toggle' type='button'>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
