import { graphql, useStaticQuery } from 'gatsby';

// eslint-disable-next-line import/no-unused-modules
export const useArticlesImage = () => {
    const data = useStaticQuery(graphql `
        query ArticlesImagesHookDesktop {
            screenshot1: file(relativePath : { eq: "resources/Screenshot1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 400
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            screenshot2: file(relativePath : { eq: "resources/Screenshot2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot3: file(relativePath : { eq: "resources/Screenshot3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot4: file(relativePath : { eq: "resources/Screenshot4.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot5: file(relativePath : { eq: "resources/Screenshot5.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot6: file(relativePath : { eq: "resources/Screenshot6.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot7: file(relativePath : { eq: "resources/Screenshot7.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot8: file(relativePath : { eq: "resources/Screenshot8.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot9: file(relativePath : { eq: "resources/Screenshot9.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot10: file(relativePath : { eq: "resources/Screenshot10.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            businessApps: file(relativePath : { eq: "resources/Business_Applications_Performance_Monitoring.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            unified: file(relativePath : { eq: "resources/Unified_Communications_Monitoring.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            mvnoQoe: file(relativePath : { eq: "resources/MVNO_QoE_Monitoring.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            voiceServices: file(relativePath : { eq: "resources/Voice_Services.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            videoConferencing: file(relativePath : { eq: "resources/Deep_Dive_Banner.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            ivr: file(relativePath : { eq: "resources/witbox-rack.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot11: file(relativePath : { eq: "resources/Screenshot11.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            screenshot12: file(relativePath : { eq: "resources/Screenshot12.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            snapshot3Picture5: file(relativePath : { eq: "resources/Snapshot3Picture5.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            snapshot6Picture1: file(relativePath : { eq: "resources/Snapshot6Picture1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            snapshot7Picture1: file(relativePath : { eq: "resources/Snapshot7Picture1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            fiveGBanner: file(relativePath : { eq: "resources/banners/5g_Banner2.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            superbowlBanner: file(relativePath : { eq: "resources/banners/superbowlbanner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            appleBanner: file(relativePath : { eq: "resources/banners/applebanner.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            londonBanner: file(relativePath : { eq: "resources/banners/londonbanner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            OTTUkBanner: file(relativePath : { eq: "resources/banners/OTT_UK_Banner.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            RECUseCase: file(relativePath : { eq: "resources/REC_Grid_Image.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            deepDive: file(relativePath : { eq: "resources/deep_dive_grid.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            smartTV: file(relativePath : { eq: "resources/SmartTVGrid.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            sodexo: file(relativePath : { eq: "resources/Sodexo.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 400)
                }
            },
            latency5g: file(relativePath : { eq: "resources/Cloud_Gaming_Over_5GTile_Square.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 400
                    )
                }
            },
            unitedCloud: file(relativePath : { eq: "articles/united-cloud.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            witboxPlus: file(relativePath : { eq: "blog/thumbs/witboxplus-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            witboxhand: file(relativePath : { eq: "blog/thumbs/witbox_hand_thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            stormtest: file(relativePath : { eq: "blog/thumbs/stormtest_alt.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            witbox: file(relativePath : { eq: "blog/thumbs/meet_the_witbox.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nfl: file(relativePath : { eq: "blog/thumbs/nfl_draft_2021.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            android: file(relativePath : { eq: "blog/thumbs/android_tv_test.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            witboxIllo: file(relativePath : { eq: "blog/thumbs/witbox-illustration.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            sodexowitbe: file(relativePath : { eq: "blog/thumbs/Sodex-x-Witbe.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 822
                    )
                }
            },
            cloud: file(relativePath : { eq: "blog/thumbs/cloud.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            slingboxBlog: file(relativePath : { eq: "blog/thumbs/slingbox_alt.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            MegaHertz: file(relativePath : { eq: "blog/thumbs/MegaHertz.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            qoe: file(relativePath : { eq: "blog/thumbs/why_qoe.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            cloudGaming: file(relativePath : { eq: "blog/thumbs/cloud_gaming.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            eas: file(relativePath : { eq: "blog/thumbs/EAS.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            newLogo: file(relativePath : { eq: "blog/thumbs/new_visual_identity.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            btr: file(relativePath : { eq: "blog/thumbs/btr-award-hero.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            tvb: file(relativePath : { eq: "blog/thumbs/rec-coffee-guy.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            tvbMobile: file(relativePath : { eq: "blog/thumbs/laptop-coffee-guy-rec-and-smartgate.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            best2022: file(relativePath : { eq: "blog/thumbs/best-in-market-2022-article-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            best2022Mobile: file(relativePath : { eq: "blog/thumbs/best-in-market-2022-article-banner-mobile.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            singapore: file(relativePath : { eq: "blog/thumbs/og-image-singapore-office.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            singaporeMobile: file(relativePath : { eq: "blog/thumbs/og-image-singapore-office.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tmobile: file(relativePath : { eq: "blog/thumbs/tmobile_og_image.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            tmobileBanner: file(relativePath : { eq: "blog/thumbs/tmobile-article_hero_banner_15x.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            tmobileMobile: file(relativePath : { eq: "blog/thumbs/tmobile-og-image.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tmobileSquare: file(relativePath : { eq: "blog/thumbs/tmobile-square-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            shortform: file(relativePath : { eq: "blog/2023/shortform/short-form-video-og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            shortformBanner: file(relativePath : { eq: "blog/2023/shortform/short-form-video-article-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            shortformMobile: file(relativePath : { eq: "blog/2023/shortform/short-form-video-mobile_thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            shortformSquare: file(relativePath : { eq: "blog/2023/shortform/short-form-video-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            montreal: file(relativePath : { eq: "blog/2023/montreal/Montreal_og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            montrealBanner: file(relativePath : { eq: "blog/2023/montreal/Montreal_article-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1920
                    )
                }
            },
            montrealMobile: file(relativePath : { eq: "blog/2023/montreal/Montreal_mobile_thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            montrealSquare: file(relativePath : { eq: "blog/2023/montreal/Montreal_article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nab: file(relativePath : { eq: "blog/2023/nab/product-of-the-year-award-og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nabBanner: file(relativePath : { eq: "blog/2023/nab/product-of-the-year-award-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1632
                    )
                }
            },
            nabMobile: file(relativePath : { eq: "blog/2023/nab/product-of-the-year-award-mobile thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nabSquare: file(relativePath : { eq: "blog/2023/nab/product-of-the-year-award-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nabFooterList: file(relativePath : { eq: "blog/nab-product-of-the-year.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            shortformFooterList: file(relativePath : { eq: "blog/short-form-video.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            best2022FooterList: file(relativePath : { eq: "blog/best-in-market-2022.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            slingboxFooterList: file(relativePath : { eq: "technology/best-slingbox-alternative.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            witboxFooterList: file(relativePath : { eq: "blog/witbox-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            fastFooterList: file(relativePath : { eq: "blog/fast-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            smartnavFooterList: file(relativePath : { eq: "blog/smart-navigate-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            montrealFooterList: file(relativePath : { eq: "blog/montreal-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            fast: file(relativePath : { eq: "blog/2023/fast/og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            fastBanner: file(relativePath : { eq: "blog/2023/fast/article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1632
                    )
                }
            },
            fastMobile: file(relativePath : { eq: "blog/2023/fast/mobile-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            fastSquare: file(relativePath : { eq: "blog/2023/fast/desktop-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 300
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            smartnav: file(relativePath : { eq: "blog/2023/smartnav/smart-navigate-og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            smartnavBanner: file(relativePath : { eq: "blog/2023/smartnav/smart-navigate-article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1632
                    )
                }
            },
            smartnavMobile: file(relativePath : { eq: "blog/2023/smartnav/smart-navigate-mobile-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            smartnavSquare: file(relativePath : { eq: "blog/2023/smartnav/smart-navigate-desktop-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 300
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            ad: file(relativePath : { eq: "blog/2023/ad/og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            adBanner: file(relativePath : { eq: "blog/2023/ad/article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1632
                    )
                }
            },
            adMobile: file(relativePath : { eq: "blog/2023/ad/mobile-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            adSquare: file(relativePath : { eq: "blog/2023/ad/desktop-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 300
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            adFooterList: file(relativePath : { eq: "blog/ad-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            btr2023: file(relativePath : { eq: "blog/2023/btr/og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            btr2023Banner: file(relativePath : { eq: "blog/2023/btr/article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1632
                    )
                }
            },
            btr2023Mobile: file(relativePath : { eq: "blog/2023/btr/mobile-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            btr2023Square: file(relativePath : { eq: "blog/2023/btr/desktop-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 300
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            btr2023FooterList: file(relativePath : { eq: "blog/btr-2023-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            techrewind2023: file(relativePath : { eq: "blog/2024/tech-rewind/og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            techrewind2023Banner: file(relativePath : { eq: "blog/2024/tech-rewind/article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            techrewind2023Mobile: file(relativePath : { eq: "blog/2024/tech-rewind/mobile-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            techrewind2023Square: file(relativePath : { eq: "blog/2024/tech-rewind/desktop-article-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            techrewind2023FooterList: file(relativePath : { eq: "blog/tech-rewind-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            channel4: file(relativePath : { eq: "blog/2024/channel4/og-image.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            channel4Banner: file(relativePath : { eq: "blog/2024/channel4/article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            channel4BannerHero: file(relativePath : { eq: "blog/2024/channel4/channel4-hero.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            channel4Mobile: file(relativePath : { eq: "blog/2024/channel4/og-image.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            channel4Square: file(relativePath : { eq: "blog/2024/channel4/desktop-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            channel4FooterList: file(relativePath : { eq: "blog/channel4-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            abr: file(relativePath : { eq: "blog/2024/abr/og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            abrBanner: file(relativePath : { eq: "blog/2024/abr/article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            abrBannerHero: file(relativePath : { eq: "blog/2024/abr/article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            abrMobile: file(relativePath : { eq: "blog/2024/abr/og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            abrSquare: file(relativePath : { eq: "blog/2024/abr/desktop-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            abrFooterList: file(relativePath : { eq: "blog/2024/abr/abr-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nabpoty: file(relativePath : { eq: "blog/2024/nab-poty/NAB-Product-of-the-Year-og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nabpotyBanner: file(relativePath : { eq: "blog/2024/nab-poty/NAB-Product-of-the-Year-article-featured-banner-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nabpotyBannerHero: file(relativePath : { eq: "blog/2024/nab-poty/NAB-Product-of-the-Year-article-featured-banner-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nabpotyMobile: file(relativePath : { eq: "blog/2024/nab-poty/NAB-Product-of-the-Year-og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nabpotySquare: file(relativePath : { eq: "blog/2024/nab-poty/NAB-Product-of-the-Year-desktop-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nabpotyFooterList: file(relativePath : { eq: "blog/2024/nab-poty/NAB-Product-of-the-Year-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nabbos: file(relativePath : { eq: "blog/2024/nab-bos/NAB-Best-of-Show-og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nabbosBanner: file(relativePath : { eq: "blog/2024/nab-bos/NAB-Best-of-Show-article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nabbosBannerHero: file(relativePath : { eq: "blog/2024/nab-bos/NAB-Best-of-Show-article-featured-banner.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nabbosMobile: file(relativePath : { eq: "blog/2024/nab-bos/NAB-Best-of-Show-og-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nabbosSquare: file(relativePath : { eq: "blog/2024/nab-bos/NAB-Best-of-Show-desktop-article-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nabbosFooterList: file(relativePath : { eq: "blog/2024/nab-bos/NAB-Best-of-Show-favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            paulBunyanSquare: file(relativePath : { eq: "blog/2024/paul-bunyan/PB-assets-05.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            paulBunyanBanner: file(relativePath : { eq: "blog/2024/paul-bunyan/PB-assets_thumbnail_homepage.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            paulBunyanMobile: file(relativePath : { eq: "blog/2024/paul-bunyan/PB-assets_thumbnail_favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            paulBunyanFooterList: file(relativePath : { eq: "blog/2024/paul-bunyan/PB-assets_thumbnail_favorite.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            bouyguesSquare: file(relativePath : { eq: "blog/2024/bouygues/bt-asset-articles-square-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            bouyguesBanner: file(relativePath : { eq: "blog/2024/bouygues/bt-asset-articles-feature-highlight.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            bouyguesMobile: file(relativePath : { eq: "blog/2024/bouygues/bt-asset-articles-content-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            bouyguesFooterList: file(relativePath : { eq: "blog/2024/bouygues/bt-asset-articles-content-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            csiAwardSquare: file(relativePath : { eq: "blog/2024/csiAward/csi-asset-articles-square-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            csiAwardBanner: file(relativePath : { eq: "blog/2024/csiAward/csi-asset-articles-feature-highlight.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            csiAwardMobile: file(relativePath : { eq: "blog/2024/csiAward/csi-asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            csiAwardFooterList: file(relativePath : { eq: "blog/2024/csiAward/csi-asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            bsAwardSquare: file(relativePath : { eq: "blog/2024/bsAward/tv-asset-articles-square-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            bsAwardBanner: file(relativePath : { eq: "blog/2024/bsAward/tv-asset-articles-feature-highlight.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            bsAwardMobile: file(relativePath : { eq: "blog/2024/bsAward/tv-asset-articles-content-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            bsAwardFooterList: file(relativePath : { eq: "blog/2024/bsAward/tv-asset-articles-content-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            dtrSquare: file(relativePath : { eq: "blog/2024/dtr/dtr-asset-articles-square-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            dtrBanner: file(relativePath : { eq: "blog/2024/dtr/dtr-asset-articles-feature-highlight.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            dtrMobile: file(relativePath : { eq: "blog/2024/dtr/dtr-asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            dtrFooterList: file(relativePath : { eq: "blog/2024/dtr/dtr-asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nocSquare: file(relativePath : { eq: "blog/2024/noc/noc-asset-articles-square-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nocBanner: file(relativePath : { eq: "blog/2024/noc/noc-asset-articles-feature-highlight.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nocMobile: file(relativePath : { eq: "blog/2024/noc/noc-asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nocFooterList: file(relativePath : { eq: "blog/2024/noc/noc-asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            dubaiNewOfficeSquare: file(relativePath : { eq: "blog/2024/dubaiNewOffice/Dubai-Office-Opening-Website_asset-articles-square-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            dubaiNewOfficeBanner: file(relativePath : { eq: "blog/2024/dubaiNewOffice/Dubai-Office-Opening-Website_asset-articles-feature-highlight.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            dubaiNewOfficeMobile: file(relativePath : { eq: "blog/2024/dubaiNewOffice/Dubai-Office-Opening-Website_asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            dubaiNewOfficeFooterList: file(relativePath : { eq: "blog/2024/dubaiNewOffice/Dubai-Office-Opening-Website_asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            msctvSquare: file(relativePath : { eq: "blog/2024/msctv/msctv-_asset-articles-square-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            msctvBanner: file(relativePath : { eq: "blog/2024/msctv/msctv-_asset-articles-feature-highlight-.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            msctvMobile: file(relativePath : { eq: "blog/2024/msctv/msctv-asset-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            msctvFooterList: file(relativePath : { eq: "blog/2024/msctv/msctv-asset-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nocNovemberSquare: file(relativePath : { eq: "blog/2024/noc-november/noc_asset-articles-square-thumbnail-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nocNovemberBanner: file(relativePath : { eq: "blog/2024/noc-november/noc_asset-articles-feature-highlight.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nocNovemberMobile: file(relativePath : { eq: "blog/2024/noc-november/noc_asset-articles-square-thumbnail-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nocNovemberFooterList: file(relativePath : { eq: "blog/2024/noc-november/noc_asset-home-thumbnail-min.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nocBenefitsSquare: file(relativePath : { eq: "blog/2024/noc-benefits/PM_asset-articles-square-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nocBenefitsMobile: file(relativePath : { eq: "blog/2024/noc-benefits/PM_asset-articles-square-thumbnail-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nocBenefitsFooterList: file(relativePath : { eq: "blog/2024/noc-benefits/PM_asset-home-landscape.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            dtgPrSquare: file(relativePath : { eq: "blog/2025/dtg-pr/DTG-Website-Article_asset-articles-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            dtgPrMobile: file(relativePath : { eq: "blog/2025/dtg-pr/DTG-Website-Article_asset-articles-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            dtgPrFooterList: file(relativePath : { eq: "blog/2025/dtg-pr/DTG-Website-Article_asset-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            techRewind24Square: file(relativePath : { eq: "blog/2025/tech-rewind-2024/tech-rewind-asset-articles-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            techRewind24Mobile: file(relativePath : { eq: "blog/2025/tech-rewind-2024/tech-rewind-asset-articles-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            techRewind24FooterList: file(relativePath : { eq: "blog/2025/tech-rewind-2024/tech-rewind-asset-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            feedHonoursSquare: file(relativePath : { eq: "blog/2025/feed-honours/FEED-Website-Article_asset-articles-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            feedHonoursMobile: file(relativePath : { eq: "blog/2025/feed-honours/FEED-Website-Article_asset-articles-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            feedHonoursFooterList: file(relativePath : { eq: "blog/2025/feed-honours/FEED-Website-Article_asset-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            qaVNocSquare: file(relativePath : { eq: "blog/2025/qa-vnoc/qa-virtual-noc-asset-articles-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            qaVNocMobile: file(relativePath : { eq: "blog/2025/qa-vnoc/qa-virtual-noc-asset-articles-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            qaVNocFooterList: file(relativePath : { eq: "blog/2025/qa-vnoc/qa-virtual-noc-asset-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            dmwSquare: file(relativePath : { eq: "blog/2025/dmw/DMW-asset-articles-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            dmwMobile: file(relativePath : { eq: "blog/2025/dmw/DMW-asset-articles-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            dmwFooterList: file(relativePath : { eq: "blog/2025/dmw/DMW-asset-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tagPrSquare: file(relativePath : { eq: "blog/2025/tag-pr/tag-pr-article-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tagPrMobile: file(relativePath : { eq: "blog/2025/tag-pr/tag-pr-article-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            tagPrFooterList: file(relativePath : { eq: "blog/2025/tag-pr/tag-pr-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            superbowl2025Square: file(relativePath : { eq: "blog/2025/superbowl/superbowl-article-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            superbowl2025Mobile: file(relativePath : { eq: "blog/2025/superbowl/superbowl-article-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            superbowl2025FooterList: file(relativePath : { eq: "blog/2025/superbowl/superbowl-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            superbowl2025Banner: file(relativePath : { eq: "blog/2025/superbowl/superbowl-article-highlight.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                    )
                }
            },
            nctcPrSquare: file(relativePath : { eq: "blog/2025/nctc-pr/nctc-pr-article-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nctcPrMobile: file(relativePath : { eq: "blog/2025/nctc-pr/nctc-pr-article-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            nctcPrFooterList: file(relativePath : { eq: "blog/2025/nctc-pr/nctc-pr-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            lightwaveBtrAwardSquare: file(relativePath : { eq: "blog/2025/lightwave-btr-award/lightwave-btr-innovation-reviews-articles-square-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 245
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            lightwaveBtrAwardMobile: file(relativePath : { eq: "blog/2025/lightwave-btr-award/lightwave-btr-innovation-reviews-articles-square-mobile.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                    )
                }
            },
            lightwaveBtrAwardFooterList: file(relativePath : { eq: "blog/2025/lightwave-btr-award/lightwave-btr-innovation-reviews-home-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            }
        }
    `);

    return data;
};
