import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../common/styles/salesforce.module.scss';

const propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    maxLength: PropTypes.number,
    type: PropTypes.string,
};

const defaultProps = {
    value: null,
    error: null,
    onChange: null,
    isRequired: false,
    maxLength: null,
    type: 'text',
};

const FormInput = ({ id, label, value, error, onChange, isRequired, maxLength, type, ...props }) => (
    <div className={isRequired ? styles.required : styles.customInput}>
        <label htmlFor={id}>{label}</label>
        <input
            id={id}
            name={id}
            className={`${styles.inputStyle}${error ? ' error' : ''}`}
            maxLength={maxLength}
            onChange={onChange}
            size='20'
            type={type}
            required={isRequired}
            {...([ 'email', 'tel' ].includes(type) && { autoComplete: type })}
            {...(value !== null && { value })}
            {...props}
        />
        {error
            && <div className={styles.errorWrapper}>{error}</div>}
    </div>
);

FormInput.propTypes = propTypes;
FormInput.defaultProps = defaultProps;

export default FormInput;
