import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../common/styles/salesforce.module.scss';

const propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string.isRequired),
        PropTypes.string.isRequired
    ])),
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
};

const defaultProps = {
    title: null,
    options: [],
    value: null,
    error: null,
    onChange: null,
    isRequired: false,
};

const FormSelect = ({ id, label, title, options, value, error, onChange, isRequired }) => (
    <div className={isRequired ? styles.required : styles.customInput}>
        <label htmlFor={id}>{label}</label>
        <select
            id={id}
            name={id}
            title={title}
            className={`${styles.inputStyle}${error ? ' error' : ''}`}
            onChange={onChange}
            onBlur={onChange}
            {...(value !== null && { value })}
            required={isRequired}
        >
            <option value='' />
            {
                Array.isArray(options[0])
                    ? options.map(([optionValue, optionLabel]) =>
                        <option value={optionValue} key={optionValue}>{optionLabel}</option>
                    )
                    : options.map(optionLabel =>
                        <option value={optionLabel} key={optionLabel}>{optionLabel}</option>
                    )
            }
        </select>
        {error && <div className={styles.errorWrapper}>{error}</div>}
    </div>
);

FormSelect.propTypes = propTypes;
FormSelect.defaultProps = defaultProps;

export default FormSelect;
