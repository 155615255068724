import React from 'react';

import { viewportContext } from '../context';

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
export const useViewport = () => {
    /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
    const { width, height } = React.useContext(viewportContext);

    const isSmallerMobileBreakpoint = width <= 480;
    const isMobileBreakpoint = width <= 500;
    const isTabletBreakpoint = width <= 821;
    const isSmallBreakpoint = width <= 1050;

    return { width, height, isSmallerMobileBreakpoint, isMobileBreakpoint, isTabletBreakpoint, isSmallBreakpoint };
};
