/**
 * Build the string we need to display an event date span.
 * @param {string} startDateString - The beginning date of an event, "12/31/2024" format.
 * @param {string} endDateString - The end date of an event, "12/31/2024" format.
 * @example
 * // returns "February 18–20"
 * formatEventDate("02/18/2025", "02/20/2025");
 * @example
 * // returns "March 6"
 * formatEventDate("03/06/2024", "03/06/2024");
 * @returns {Number} Returns the formatted string.
 */
export const formatEventDate = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const options = { month: 'long', day: 'numeric' };
    const startMonthDay = startDate.toLocaleDateString('en-US', options);

    if (startDateString === endDateString) {
        return startMonthDay;
    }

    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleDateString('en-US', { month: 'long' });
    const endDay = endDate.getDate();
    return `${startMonth} ${startDay}–${endDay}`;
}

const getNumberOrdinal = (number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

/**
 * Formats the date string with ordinal number suffix.
 * @param {string} dateString - A date, "12/31/2024" format.
 * @example
 * // returns "February 19th, 2025"
 * formatDateWithOrdinal("02/19/2025");
 * @returns {Number} Returns the formatted string.
 */
export const formatDateWithOrdinal = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();

    return `${month} ${day}${getNumberOrdinal(day)}, ${year}`;
}
