export const FORM_TYPES = {
    CONTACT_US: 'contact-us',
    MEETING: 'meeting-form',
    NEWSLETTER: 'newsletter',
}

export const SALESFORCE_FIELDS_NAME = {
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    EMAIL: 'email',
    PHONE: 'phone',
    COUNTRY_CODE: 'country_code',
    COMPANY: '00NHp00000xCwwM',
    HEARD_ABOUT_US: '00NHp00000xCwxo',
    INDUSTRY: '00NHp00000qF5jy',
    MESSAGE: '00NHp00000xCy2H',
    MARKETING_EMAILS: '00NHp00000xCwxt',
    ACKNOWLEDGEMENT: 'acknowledgement'
};

/**
 * Production settings
 */
// This key is associated to the marketing google account
export const CAPTCHA_PUBLIC_KEY = '6LcQceApAAAAANNYFpMmSswUBDL9ZCDWle3nhajx';
export const CAPTCHA_PUBLIC_KEY_INVISIBLE = '6LdOZz0aAAAAAPh8T0c4r4o65HV3EttgVWxn8g9L';
export const SALESFORCE_ORG_ID = '00DHp000003VA51';
export const SALESFORCE_ADD_LEAD_FORM_ACTION = `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=${SALESFORCE_ORG_ID}`;

/*
 * Testing settings
 */
// For testing purposes, you can use the following keys :
//   - Site key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
//   - Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe
// (https://developers.google.com/recaptcha/docs/faq)
export const CAPTCHA_PUBLIC_KEY_DEBUG = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
// export const SALESFORCE_ORG_ID = '00DD4000000Hsdx';
// export const SALESFORCE_ADD_LEAD_FORM_ACTION = `https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=${SALESFORCE_ORG_ID}`;
