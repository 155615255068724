import React, { useContext, useEffect, useMemo } from 'react';

import { ModalContext } from '../../context';
import { useRedirectModals } from '../../hooks';
import { FORM_TYPES, MODAL_TYPES, SALESFORCE_FIELDS_NAME } from '../../constants';

import GenericModal from '../ui/GenericModal/GenericModal';
import SalesforceForm from '../forms/common/SalesforceForm';
import FormFeedback from '../forms/FormFeedback';

const { CONTACT_US, NEWSLETTER, SUCCESS } = MODAL_TYPES;
const { PHONE, COMPANY, HEARD_ABOUT_US, MESSAGE, MARKETING_EMAILS } = SALESFORCE_FIELDS_NAME;

const ModalController = () => {
    const { closeModal, isModalOpen, modalType, successModalVariant } = useContext(ModalContext);
    const { onCloseFormSuccessModal } = useRedirectModals();

    const modalContent = useMemo(() => {
        switch (modalType) {
            case NEWSLETTER:
                return (
                    <SalesforceForm
                        type={FORM_TYPES.NEWSLETTER}
                        title='Subscribe to our newsletter!'
                        excludedFields={[ PHONE, COMPANY, HEARD_ABOUT_US, MESSAGE, MARKETING_EMAILS ]}
                        onCancel={closeModal}
                        isModal
                    />
                );
            case CONTACT_US:
                return (
                    <SalesforceForm
                        type={FORM_TYPES.CONTACT_US}
                        title='Contact us'
                        onCancel={closeModal}
                        isModal
                    />
                );
            case SUCCESS:
                return <FormFeedback variant={successModalVariant} />;
            default:
                return null;
        }
    }, [ modalType, closeModal, successModalVariant ]);

    if (!modalType) {
        return null;
    }

    return (
        <GenericModal
            isOpen={isModalOpen}
            onClose={modalType === SUCCESS ? onCloseFormSuccessModal : closeModal}
        >
            {modalContent}
        </GenericModal>
    );
};

export default ModalController;
