import { graphql, useStaticQuery } from 'gatsby';

export const useExternalArticles = () => {
    // Do not remove the following comment. It's used by scripts/add-external-link.py
    // <!--[codegen-images]-->
    const data = useStaticQuery(graphql `
        query ExternalArticlesImagesHookDesktop {
            lightwave_oneline2272025: file(relativePath : { eq: "blog/external-articles/witbe-noc.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            advanced_television2192025: file(relativePath : { eq: "blog/external-articles/witbe-nctc-partnership.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            rethink2132025: file(relativePath : { eq: "blog/external-articles/tubi-super-bowl.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tvtechnology2112025: file(relativePath : { eq: "blog/external-articles/witbe-tag-partnership.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            inbroadcast1152025: file(relativePath : { eq: "blog/external-articles/witbe-dtg-partnership.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            newscaststudio1132025: file(relativePath : { eq: "blog/external-articles/news-cast-studio-insights.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            digital_media_world12022024: file(relativePath : { eq: "blog/external-articles/digital-media-world-award-virtual-noc.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            feed1262024: file(relativePath : { eq: "blog/external-articles/sprint44-feed.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tkt_195711202024: file(relativePath : { eq: "blog/external-articles/sprint44-TKT1957.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            advanced_television10222024: file(relativePath : { eq: "blog/external-articles/sprint44-AdvTV-virtual-noc-min.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            euromedia030024: file(relativePath : { eq: "blog/external-articles/2024_03_00_euro-media.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            streamingmedia032124: file(relativePath : { eq: "blog/external-articles/2024_03_21_streaming-media.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            newscast040824: file(relativePath : { eq: "blog/external-articles/2024_04_08_newscast-studio.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            newscast040924: file(relativePath : { eq: "blog/external-articles/2024_04_09_newscast-studio.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            inbroadcast042924: file(relativePath : { eq: "blog/external-articles/2024_04_29_inbroadcast.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tvtech050324: file(relativePath : { eq: "blog/external-articles/2024_05_03_tvtech.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            scte050024: file(relativePath : { eq: "blog/external-articles/2024_05_00_scte-broadband-journal.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            scte0500241: file(relativePath : { eq: "blog/external-articles/2024_05_00_scte-broadband-journal_mosaic.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            fourrfv05162024: file(relativePath : { eq: "blog/external-articles/Sprint36-4rfv-1605.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            apb_news05292024: file(relativePath : { eq: "blog/external-articles/Sprint36-apb_news-0529.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tv_technology06252024: file(relativePath : { eq: "blog/external-articles/Sprint36-tv_technology-0625.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            advanced_television06252024: file(relativePath : { eq: "blog/external-articles/Sprint36-Advanced_Television-0625.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            inbroadcast_08012024: file(relativePath : { eq: "blog/external-articles/Sprint37-InBroadcast--0801.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            light_reading07312024: file(relativePath : { eq: "blog/external-articles/Sprint37-TV_Light_reading-0731.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tv_news_check07232024: file(relativePath : { eq: "blog/external-articles/Sprint37-TV_News_Check_IBC-0723.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tv_news_check07082024: file(relativePath : { eq: "blog/external-articles/Sprint37-TV_News_Check_Paul B-0708.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            ibc_org08292024: file(relativePath : { eq: "blog/external-articles/Sprint39-Mobile-Automation-Setup.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            ibc_org08272024bis: file(relativePath : { eq: "blog/external-articles/Sprint39-Content-everywhere.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            ibc_org08272024: file(relativePath : { eq: "blog/external-articles/Sprint39-MPL.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            content_technology08272024: file(relativePath : { eq: "blog/external-articles/Sprint39-Witbe-Unveils-New-Mobile-Automation.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            ibc_org9202024: file(relativePath : { eq: "blog/external-articles/sprint40-ibc-dynamic-ad-insertion.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tv_tech9162024: file(relativePath : { eq: "blog/external-articles/sprint40-tvtech-best-of-show.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            webdiscloser9122024: file(relativePath : { eq: "blog/external-articles/sprint40-web-discloser.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            csi_magazine932024: file(relativePath : { eq: "blog/external-articles/sprint40-csi-magazine.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            advanced_television9262024: file(relativePath : { eq: "blog/external-articles/sprint41-advanced-television.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            telecomparer9262024: file(relativePath : { eq: "blog/external-articles/sprint41-telecomparer.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            ibcorg9242024: file(relativePath : { eq: "blog/external-articles/sprint41-ibc-365.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            iabm_journal9202024: file(relativePath : { eq: "blog/external-articles/sprint41-iabm-Journal.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tvtech_logo: file(relativePath : { eq: "blog/external-articles/tvtech-logo.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            newscaststudio: file(relativePath : { eq: "blog/external-articles/newscaststudio.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tvb_europe1022024: file(relativePath : { eq: "blog/external-articles/sprint42-tvb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tv_news_check1022024: file(relativePath : { eq: "blog/external-articles/sprint42-tvnewscheck.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            advanced_television1022024: file(relativePath : { eq: "blog/external-articles/sprint43-AdvTV-virtual-noc.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            tm_broadcast1072024: file(relativePath : { eq: "blog/external-articles/sprint43-TMbroadcast.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            lightwave10152024: file(relativePath : { eq: "blog/external-articles/sprint43-BTR.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            advanced_television10152024: file(relativePath : { eq: "blog/external-articles/sprint43-AdvTV.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 421
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            }
        }
    `);

    const externalArticlesDataArray = [
        // Do not remove the following comment. It's used by scripts/add-external-link.py
        // <!--[codegen-data]-->

        {
            postId: 52,
            link: 'https://www.lightwaveonline.com/home/article/55270073/witbe-virtual-noc-technology',
            image: data.lightwave_oneline2272025.childImageSharp.gatsbyImageData,
            publisher: 'Lightwave Oneline',
            name: 'Lightwave+BTR Innovation Review - Witbe: Virtual NOC Technology',
            date: 'February 27, 2025',
            alt: 'Lightwave Oneline article',
            type: 'Award'
        },
        {
            postId: 51,
            link: 'https://www.advanced-television.com/2025/02/19/witbe-joins-the-nctc/',
            image: data.advanced_television2192025.childImageSharp.gatsbyImageData,
            publisher: 'Advanced Television',
            name: '"We are thrilled to join NCTC and support its community of independent video providers"',
            date: 'February 19, 2025',
            alt: 'Advanced Television article',
            type: 'Membership'
        },
        {
            postId: 50,
            link: 'https://rethinkresearch.biz/articles/tubi-defeats-spectrum-at-super-bowl-time-to-end-ott-latency-debate/',
            image: data.rethink2132025.childImageSharp.gatsbyImageData,
            publisher: 'Rethink',
            name: 'Tubi defeats Spectrum at Super Bowl\u2014time to end OTT latency debate?',
            date: 'February 13, 2025',
            alt: 'Rethink article',
            type: 'Partnerships'
        },
        {
            postId: 49,
            link: 'https://www.tvtechnology.com/news/tag-video-systems-witbe-team-up-on-end-to-end-video-monitoring',
            image: data.tvtechnology2112025.childImageSharp.gatsbyImageData,
            publisher: 'TV Technology',
            name: 'TAG Video Systems, Witbe Team Up on End-to-End Video Monitoring',
            date: 'February 11, 2025',
            alt: 'TV Technology article',
            type: 'Partnerships'
        },

        {
            postId: 48,
            link: 'https://issuu.com/futurepublishing/docs/tvb111.digital_tvb_jan_feb_2025',
            image: data.advanced_television10222024.childImageSharp.gatsbyImageData,
            publisher: 'TVB Europe',
            name: 'TVB Europe - The virtual NOC: a new era for video testing and monitoring',
            date: 'January 20, 2025',
            alt: 'TVB Europe article',
            type: 'Technology '
        },
        {
            postId: 47,
            link: 'https://www.newscaststudio.com/2025/01/16/industry-insights-regulatory-changes-and-sustainability-concerns-grow-in-2025/',
            image: data.newscaststudio1132025.childImageSharp.gatsbyImageData,
            publisher: 'NewscastStudio',
            name: 'NewscastStudio: Regulatory and sustainability concerns grow in 2025',
            date: 'January 16, 2025',
            alt: 'NewscastStudio article',
            type: 'Insight'
        },
        {
            postId: 46,
            link: 'https://www.inbroadcast.com/news/witbe-joins-the-digital-tv-group',
            image: data.inbroadcast1152025.childImageSharp.gatsbyImageData,
            publisher: 'InBroadcast',
            name: 'InBroadcast - Witbe Joins the Digital TV Group',
            date: 'January 15, 2025',
            alt: 'InBroadcast article',
            type: 'Membership'
        },
        {
            postId: 45,
            link: 'https://www.newscaststudio.com/2025/01/14/industry-insights-mobile-consumption-personalization-and-changing-viewer-habits/',
            image: data.newscaststudio1132025.childImageSharp.gatsbyImageData,
            publisher: 'NewscastStudio',
            name: 'NewscastStudio: Mobile viewing, personalization and changing viewer habits',
            date: 'January 14, 2025',
            alt: 'NewscastStudio article',
            type: 'Insight'
        },

        {
            postId: 44,
            link: 'https://www.newscaststudio.com/2025/01/13/industry-insights-ai-cloud-and-flexibility-define-2025-in-broadcasting/',
            image: data.newscaststudio1132025.childImageSharp.gatsbyImageData,
            publisher: '',
            name: 'Industry Insights: AI, cloud and flexibility set to define 2025 in broadcasting',
            date: 'January 13, 2025',
            alt: ' article',
            type: 'Insight'
        },
        {
            postId: 43,
            link: 'https://www.newscaststudio.com/2025/01/06/outlook-trends-defining-broadcast-and-media-in-2025/',
            image: data.newscaststudio.childImageSharp.gatsbyImageData,
            publisher: 'NewscastStudio',
            name: 'NewscastStudio - Ten trends defining broadcast and media in 2025',
            date: 'January 06, 2025',
            alt: 'NewscastStudio article',
            type: 'Insight'
        },
        {
            postId: 42,
            link: 'https://www.tvtechnology.com/opinion/ai-powered-video-testing-is-a-game-changer-for-streaming',
            image: data.tvtech_logo.childImageSharp.gatsbyImageData,
            publisher: 'TV Tech',
            name: 'TV Tech - AI-powered video testing is a game-changer for streaming by Y. Hinard (COO at Witbe)',
            date: 'December 11, 2024',
            alt: 'TV Tech article',
            type: 'Insight'
        },
        {
            postId: 41,
            link: 'https://www.digitalmediaworld.tv/awards/witbe-virtual-noc-technology',
            image: data.digital_media_world12022024.childImageSharp.gatsbyImageData,
            publisher: 'Digital Media World',
            name: 'Virtual NOC technology wins Best Streaming Technology at Digital Media World',
            date: 'December 02, 2024',
            alt: 'Digital Media World article',
            type: 'Award'
        },
        {
            postId: 40,
            link: 'https://online.bright-publishing.com/view/282866517/19/',
            image: data.feed1262024.childImageSharp.gatsbyImageData,
            publisher: 'FEED',
            name: 'FEED - Marie-V\u00E9ronique Lacaze awarded by FEED Honours List 2024',
            date: 'December 06, 2024',
            alt: 'FEED article',
            type: 'Award'
        },
        {
            postId: 39,
            link: 'https://issuu.com/futurepublishing/docs/tvt503.digital_tvt_nov_2024/32 ',
            image: data.tvtech_logo.childImageSharp.gatsbyImageData,
            publisher: 'TV Tech',
            name: 'TV Tech - Buyers Briefs: Witbe\'s Remote Eye Controller',
            date: 'November 25, 2024',
            alt: 'TV Tech article',
            type: 'Technology'
        },
        {
            postId: 38,
            link: 'https://tkt1957.com/mathieu-planche-witbe/ ',
            image: data.tkt_195711202024.childImageSharp.gatsbyImageData,
            publisher: 'TKT 1957',
            name: 'TKT 1957 - Mathieu Planche, Witbe: "Large-scale update to our virtual NOC"',
            date: 'November 20, 2024',
            alt: 'TFT 1957 article',
            type: 'Interview'
        },
        {
            postId: 37,
            link: 'https://advanced-television.com/2024/10/22/msctv-chooses-witbes-virtual-noc/',
            image: data.advanced_television10222024.childImageSharp.gatsbyImageData,
            publisher: 'Advanced Television',
            name: 'Advanced Television - MSCTV chooses Witbe\u2019s virtual NOC',
            date: 'October 22, 2024',
            alt: 'Advanced Television article',
            type: 'Partnerships'
        },

        {
            postId: 36,
            link: 'https://advanced-television.com/2024/10/15/witbe-opens-new-dubai-office/',
            image: data.advanced_television10152024.childImageSharp.gatsbyImageData,
            publisher: 'Advanced Television',
            name: 'Advanced Television - Witbe opens new Dubai office',
            date: 'October 15, 2024',
            alt: 'Advanced Television article',
            type: 'Witbe news'
        },
        {
            postId: 35,
            link: 'https://www.lightwaveonline.com/broadband/article/55235333/diamond-technology-reveiws-2024-award-ceremony',
            image: data.lightwave10152024.childImageSharp.gatsbyImageData,
            publisher: 'Lightwave',
            name: 'Lightwave + BTR - Diamond Technology Reviews 2024 award ceremony',
            date: 'October 15, 2024',
            alt: 'Lightwave article',
            type: 'Award'
        },
        {
            postId: 34,
            link: 'https://tmbroadcast.com/index.php/witbe-remote-eye-controller-noc/',
            image: data.tm_broadcast1072024.childImageSharp.gatsbyImageData,
            publisher: 'TM Broadcast',
            name: 'TM Broadcast - Witbe unveils enhanced Remote Eye Controller',
            date: 'October 07, 2024',
            alt: 'TM Broadcast article',
            type: 'Technology'
        },
        {
            postId: 33,
            link: 'https://advanced-television.com/2024/10/02/witbe-launches-new-virtual-noc/',
            image: data.advanced_television1022024.childImageSharp.gatsbyImageData,
            publisher: 'Advanced Television',
            name: 'Advanced Television - Witbe launches new virtual NOC',
            date: 'October 02, 2024',
            alt: 'Advanced Television article',
            type: 'Technology'
        },
        {
            postId: 32,
            link: 'https://tvnewscheck.com/tech/article/witbe-launches-new-virtual-noc/',
            image: data.tv_news_check1022024.childImageSharp.gatsbyImageData,
            publisher: 'TV News Check',
            name: 'TV News Check - Witbe Launches New Virtual NOC',
            date: 'October 02, 2024',
            alt: 'TV News Check article',
            type: 'Technology'
        },
        {
            postId: 31,
            link: 'https://www.tvbeurope.com/media-delivery/witbe-launches-virtual-network-operation-centre-for-video-service-providers',
            image: data.tvb_europe1022024.childImageSharp.gatsbyImageData,
            publisher: 'TVB Europe',
            name: 'TVB Europe - Witbe Launches Virtual Network Operation Center for Video Service Providers',
            date: 'October 02, 2024',
            alt: 'TVB Europe article',
            type: 'Technology'
        },
        {
            postId: 30,
            link: 'https://www.newscaststudio.com/2024/10/01/bouygues-telecom-upgrades-automated-testing-capabilities-with-witbes-witbox-technology/',
            image: data.newscaststudio.childImageSharp.gatsbyImageData,
            publisher: 'NewscastStudio',
            name: 'Newscast Studio - Bouygues Telecom Upgrades Automated Testing Capabilities with Witbe\'s Witbox+ Technology',
            date: 'October 01, 2024',
            alt: 'NewscastStudio article',
            type: 'Partnerships'
        },
        {
            postId: 29,
            link: 'https://www.tvtechnology.com/news/bouygues-telecom-taps-witbe-for-automated-testing-upgrade',
            image: data.tvtech_logo.childImageSharp.gatsbyImageData,
            publisher: 'TV Tech',
            name: 'TV Tech - Bouygues Telecom Taps Witbe for Automated Testing Upgrade',
            date: 'September 27, 2024',
            alt: 'TV Tech article',
            type: 'Partnerships'
        },
        {
            postId: 28,
            link: 'https://advanced-television.com/2024/09/26/bouygues-telecom-upgrades-automated-testing-capabilities-with-witbe/',
            image: data.advanced_television9262024.childImageSharp.gatsbyImageData,
            publisher: 'Advanced Television',
            name: 'Advanced Television - Bouygues Telecom Upgrades Automated Testing Capabilities with Witbe',
            date: 'September 26, 2024',
            alt: 'Advanced Television article',
            type: 'Partnerships'
        },
        {
            postId: 27,
            link: 'https://www.telecompaper.com/news/bouygues-upgrades-video-hardware-testing-with-witbox--1513720',
            image: data.telecomparer9262024.childImageSharp.gatsbyImageData,
            publisher: 'Telecomparer',
            name: 'Telecomparer - Bouygues upgrades video hardware with Witbox+',
            date: 'September 26, 2024',
            alt: 'Telecomparer article',
            type: 'Partnerships'
        },
        {
            postId: 26,
            link: 'https://www.ibc.org/ibc-daily/controlling-data-usage-with-strong-streaming-vital-for-video-service-providers-and-mobile-network-operators/12161.article?adredir=1',
            image: data.ibcorg9242024.childImageSharp.gatsbyImageData,
            publisher: 'IBC.org',
            name: 'IBC 365 - Controlling Data Usage with Strong Streaming is Vital for Video Service Providers and Mobile Network Operators',
            date: 'September 24, 2024',
            alt: 'IBC.org article',
            type: 'IBC 365'
        },
        {
            postId: 25,
            link: 'https://theiabm.org/journal-130/#journal-130/26/',
            image: data.iabm_journal9202024.childImageSharp.gatsbyImageData,
            publisher: 'IABM Journal',
            name: 'IABM Journal - Greening the Streaming: How Real Device Testing Can Drive Sustainability in the Media Industry',
            date: 'September 20, 2024',
            alt: 'IABM Journal article',
            type: 'Technology'
        },
        {
            postId: 24,
            link: 'https://www.ibc.org/video/dynamic-ad-insertion-errors-analyzing-common-streaming-ad-issues-on-real-devices/11365.article',
            image: data.ibc_org9202024.childImageSharp.gatsbyImageData,
            publisher: 'IBC.org',
            name: 'IBC - Dynamic Ad Insertion Errors: Analyzing Common Streaming Ad Issues On Real Devices',
            date: 'September 20, 2024',
            alt: 'IBC.org article',
            type: 'IBC 2024'
        },
        {
            postId: 23,
            link: 'https://www.tvtechnology.com/news/tv-tech-announces-best-of-show-winners-at-2024-ibc-show',
            image: data.tv_tech9162024.childImageSharp.gatsbyImageData,
            publisher: 'TV Tech',
            name: 'TV Tech - Announcing the Best of Show Winners at IBC 2024',
            date: 'September 16, 2024',
            alt: 'TV Tech article',
            type: 'Award'
        },
        {
            postId: 22,
            link: 'https://www.webdisclosure.com/article/witbe-strengthens-its-collaboration-with-bouygues-telecom-with-the-witboxplus-MpL5zcYxphJ',
            image: data.webdiscloser9122024.childImageSharp.gatsbyImageData,
            publisher: 'WebDiscloser',
            name: 'WebDiscloser - Witbe Strengthens Its Collaboration With Bouygues Telecom With the Witbox+',
            date: 'September 12, 2024',
            alt: 'WebDiscloser article',
            type: 'Use case'
        },
        {
            postId: 21,
            link: 'https://www.csimagazine.com/eblast/CSIAwards2024/shortlistBrochureDigitalEdition2024.pdf',
            image: data.csi_magazine932024.childImageSharp.gatsbyImageData,
            publisher: 'CSI Magazine',
            name: 'CSI Magazine - CSI Awards Shortlist',
            date: 'September 03, 2024',
            alt: 'CSI Magazine article',
            type: 'Award'
        },
        {
            postId: 20,
            link: 'https://www.ibc.org/ibc-daily/witbe-to-unveil-new-mobile-automation-setup/11302.article',
            image: data.ibc_org08292024.childImageSharp.gatsbyImageData,
            publisher: 'IBC.org',
            name: 'IBC 365 - Witbe To Unveil New Mobile Automation Setup',
            date: 'August 29, 2024',
            alt: 'IBC.org article',
            type: 'IBC 2024'
        },
        {
            postId: 19,
            link: 'https://www.ibc.org/features/content-everywhere-what-to-expect-at-ibc2024/11271.article',
            image: data.ibc_org08272024bis.childImageSharp.gatsbyImageData,
            publisher: 'IBC.org',
            name: 'IBC 365 - Content Everywhere: What To Expect At IBC2024',
            date: 'August 27, 2024',
            alt: 'IBC.org article',
            type: 'IBC 2024'
        },
        {
            postId: 18,
            link: 'https://www.ibc.org/ibc-daily/witbe-unveils-new-mobile-automation-for-video-app-testing-and-data-usage-benchmarking-tech-at-ibc2024/11274.article',
            image: data.ibc_org08272024.childImageSharp.gatsbyImageData,
            publisher: 'IBC.org',
            name: 'IBC 365 - Witbe Unveils New Mobile Automation For Video App Testing',
            date: 'August 27, 2024',
            alt: 'IBC.org article',
            type: 'IBC 2024'
        },
        {
            postId: 17,
            link: 'https://content-technology.com/ibc/witbe-to-unveil-mobile-automation-for-video-app-testing-and-data-usage-benchmarking/',
            image: data.content_technology08272024.childImageSharp.gatsbyImageData,
            publisher: 'Content technology',
            name: 'Content + Technology - Witbe To Unveil Mobile Automation For Video App Testing',
            date: 'August 27, 2024',
            alt: 'Content technology article',
            type: 'Use case'
        },
        {
            postId: 16,
            link: 'https://www.lightreading.com/5g/light-reading-s-leading-lights-awards-2024-the-finalists',
            image: data.light_reading07312024.childImageSharp.gatsbyImageData,
            publisher: 'Light reading',
            name: 'Light Reading\'s Leading Lights Awards 2024: The Finalists',
            date: 'July 30, 2024',
            alt: 'Light reading article',
            type: 'Award'
        },
        {
            postId: 15,
            link: 'https://inbroadcast.com/news/witbe-showcasing-at-ibc2024',
            image: data.inbroadcast_08012024.childImageSharp.gatsbyImageData,
            publisher: 'InBroadcast ',
            name: 'InBroadcast - Witbe Showcasing at IBC2024',
            date: 'July 26, 2024',
            alt: 'InBroadcast  article',
            type: 'IBC 2024'
        },
        {
            postId: 14,
            link: 'https://tvnewscheck.com/tech/article/ibc-witbe-to-unveil-new-mobile-automation-for-video-app-testing-data-usage-benchmarking-tech/',
            image: data.tv_news_check07232024.childImageSharp.gatsbyImageData,
            publisher: 'TV News Check',
            name: 'TVNewsCheck - IBC: Witbe To Unveil New Mobile Automation For Video App Testing & Data Usage Benchmarking Tech',
            date: 'July 23, 2024',
            alt: 'TV News Check article',
            type: 'Products'
        },
        {
            postId: 13,
            link: 'https://tvnewscheck.com/tech/article/paul-bunyan-communications-chooses-witbe-for-live-videomonitoring-technology/',
            image: data.tv_news_check07082024.childImageSharp.gatsbyImageData,
            publisher: 'TV News Check',
            name: 'TVNewsCheck - Paul Bunyan Communications Chooses Witbe For Live VideoMonitoring Technology',
            date: 'June 26, 2024',
            alt: 'TV News Check article',
            type: 'Partnerships'
        },
        {
            postId: 12,
            link: 'https://advanced-television.com/2024/06/25/paul-bunyan-communications-chooses-witbe/',
            image: data.tv_news_check07232024.childImageSharp.gatsbyImageData,
            publisher: 'Advanced Television',
            name: 'Advanced Television - Paul Bunyan Communications chooses Witbe',
            date: 'June 25, 2024',
            alt: 'Advanced Television article',
            type: 'Partnerships'
        },
        {
            postId: 11,
            link: 'https://www.tvtechnology.com/news/paul-bunyan-communications-chooses-witbe-monitoring-technology',
            image: data.tv_technology06252024.childImageSharp.gatsbyImageData,
            publisher: 'tv technology',
            name: 'TV Tech - Paul Bunyan Communications Chooses Witbe Monitoring Technology',
            date: 'June 25, 2024',
            alt: 'tv technology article',
            type: 'Partnerships'
        },
        {
            postId: 10,
            link: 'https://apb-news.com/witbes-ad-monitoring-matching-technology-great-for-fast-channels/',
            image: data.apb_news05292024.childImageSharp.gatsbyImageData,
            publisher: 'apb news',
            name: 'APB+ - Witbe\'s ad monitoring & matching technology great for FAST channels',
            date: 'May 29, 2024',
            alt: 'apb news article',
            type: 'Broadcast Asia'
        },
        {
            postId: 9,
            link: 'https://www.4rfv.co.uk/industrynews/308045/witbe_to_showcase_automated_testing_and_proactive_monitoring_technology',
            image: data.fourrfv05162024.childImageSharp.gatsbyImageData,
            publisher: '4rfv',
            name: 'Witbe To Showcase Automated Testing And Proactive Monitoring Technology',
            date: 'May 16, 2024',
            alt: '4rfv article',
            type: 'Broadcast Asia'
        },
        {
            postId: 8,
            link: 'https://online.flippingbook.com/view/938659480/122/',
            image: data.scte0500241.childImageSharp.gatsbyImageData,
            publisher: 'SCTE Broadband Journal',
            name: 'Witbe Expands Monitoring Scope with New ABR Stream Analysis Technology',
            date: 'May 2024',
            alt: 'SCTE Broadband Journal article',
            type: 'Products'
        },
        {
            postId: 7,
            link: 'https://online.flippingbook.com/view/938659480/72/',
            image: data.scte050024.childImageSharp.gatsbyImageData,
            publisher: 'SCTE Broadband Journal',
            name: 'Best Practices for TV Providers Transitioning from QAM to IP Video Delivery Networks',
            date: 'May 2024',
            alt: 'SCTE Broadband Journal article',
            type: 'Industry insights'
        },
        {
            postId: 6,
            link: 'https://www.tvtechnology.com/opinion/real-device-monitoring-the-key-to-resolving-streaming-errors-caused-by-dynamic-ad-insertion',
            image: data.tvtech050324.childImageSharp.gatsbyImageData,
            publisher: 'TV Tech',
            name: 'The Key to Resolving Streaming Errors Caused by Dynamic Ad Insertion',
            date: 'May 3, 2024',
            alt: 'TV Tech article Real Device Monitoring',
            type: 'Use case'
        },
        {
            postId: 5,
            link: 'https://www.youtube.com/watch?v=9k4fVc6664E',
            image: data.inbroadcast042924.childImageSharp.gatsbyImageData,
            publisher: 'InBroadcast',
            name: 'Witbe’s ABR Stream Analysis Technology at NAB 2024',
            date: 'April 29, 2024',
            alt: 'Witbe ABR Stream Analysis Technology interview video NAB 2024',
            type: 'NAB Show'
        },
        {
            postId: 4,
            link: 'https://www.newscaststudio.com/2024/04/09/nab-show-preview-2024-streaming-fast-avod-svod/',
            image: data.newscast040924.childImageSharp.gatsbyImageData,
            publisher: 'NewscastStudio',
            name: 'NAB Show Preview: Streaming as the new normal for broadcasting',
            date: 'April 9, 2024',
            alt: 'NewscastStudio NAB Show preview streaming',
            type: 'NAB Show'
        },
        {
            postId: 3,
            link: 'https://www.newscaststudio.com/2024/04/08/nab-show-preview-2024-ai-broadcast-production/',
            image: data.newscast040824.childImageSharp.gatsbyImageData,
            publisher: 'NewscastStudio',
            name: 'NAB Show Preview: AI coming to every facet of broadcast production',
            date: 'April 8, 2024',
            alt: 'NewscastStudio NAB Show preview AI',
            type: 'NAB Show'
        },
        {
            postId: 2,
            link: 'https://www.streamingmedia.com/Articles/Post/Blog/Considerations-for-Providers-Shifting-from-QAM-to-IP-Video-Delivery-Networks--163176.aspx',
            image: data.streamingmedia032124.childImageSharp.gatsbyImageData,
            publisher: 'Streaming Media',
            name: 'Considerations for Providers Shifting from QAM to IP Video Delivery Networks',
            date: 'March 21, 2024',
            alt: 'Streaming Media editorial piece',
            type: 'Industry insights'
        },
        {
            postId: 1,
            link: 'https://joom.ag/R2Ad/p14',
            image: data.euromedia030024.childImageSharp.gatsbyImageData,
            publisher: 'EuroMedia',
            name: 'FAST Money',
            date: 'February/March 2024',
            alt: 'EuroMedia Digital Media Intelligence Feb March 2024',
            type: 'Use case'
        }
    ];

    return externalArticlesDataArray;
};
