const blockedDomains = [
    '@aol.com',
    '@email.com',
    '@fexiti.com',
    '@gmail.com',
    '@gmale.com',
    '@hotmail.com',
    '@icloud.com',
    '@lead2crm.com',
    '@mac.com',
    '@me.com',
    '@ms.com',
    '@msn.com',
    '@outlook.com',
    '@shutemdown.com',
    '@yahoo.com',
    '@zoomtown.com',
    '@nd.edu',
    '@yahoo.co.uk',
    '@yahoo.calive.ca',
];

export const isEmailValid = email => !blockedDomains.some(domain => email.toLowerCase().endsWith(domain));
export const isEmailAddress = email => email.match(/^\S+@\S+\.\S+$/);
