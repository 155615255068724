import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from '../../common/styles/salesforce.module.scss';

const propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    value: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    'data-gtm-id': PropTypes.string,
};

const defaultProps = {
    value: null,
    error: null,
    onChange: null,
    isRequired: false,
    'data-gtm-id': null,
};

const FormCheckbox = ({ id, children, value, error, onChange, isRequired, 'data-gtm-id': dataGtmId}) => (
    <Fragment>
        <div className={`${styles.customInputCheckbox}${isRequired ? ` ${styles.required}` : ''}`}>
            <div className={styles.checkboxWrapper}>
                <input
                    id={id}
                    name={id}
                    className={`${styles.inputStyle}${error ? ' error' : ''}`}
                    onChange={onChange}
                    type='checkbox'
                    required={isRequired}
                    checked={value}
                    data-gtm-id={dataGtmId}
                />
            </div>
            <label htmlFor={id} className={styles.labelStyle} data-gtm-id={dataGtmId}>{children}</label>
        </div>
        {error
            && <div className={styles.errorWrapperNoMargin}>{error}</div>}
    </Fragment>
);

FormCheckbox.propTypes = propTypes;
FormCheckbox.defaultProps = defaultProps;

export default FormCheckbox;
