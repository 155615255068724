import React, { useState, useCallback, useContext } from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

import { useFormErrors, useSubscribeToNewsletter } from '../../../hooks';
import styles from '../../common/styles/salesforce.module.scss';
import { isEmailValid } from '../../../utils/validation';
import { ModalContext } from '../../../context';
import FormCaptcha from './FormCaptcha';
import FormHiddenFields from './FormHiddenFields';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormTextArea from './FormTextArea';
import FormCheckbox from './FormCheckbox';
import StatusButton from '../../ui/StatusButton/StatusButton';
import {
    COUNTRY_CODES_LIST,
    FORM_SUCCESS_MODAL_VARIANTS,
    GTM_KEYS,
    MODAL_TYPES,
    SALESFORCE_FIELDS_NAME,
    SALESFORCE_ADD_LEAD_FORM_ACTION,
} from '../../../constants';

const { NEWSLETTER, SUCCESS } = MODAL_TYPES;
const { ERROR_NEWSLETTER, YOUR_IN } = FORM_SUCCESS_MODAL_VARIANTS;
const { FORM: { CANCEL, SUBMIT, NEWSLETTER_CHECKBOX } } = GTM_KEYS;
const {
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
    PHONE,
    COMPANY,
    COUNTRY_CODE,
    HEARD_ABOUT_US,
    INDUSTRY,
    MESSAGE,
    MARKETING_EMAILS,
    ACKNOWLEDGEMENT,
} = SALESFORCE_FIELDS_NAME;

const HEARD_ABOUT_US_OPTIONS = [
    'Advertisement',
    'Search Engine',
    'Friend or Colleague',
    'Social media',
    'Blog publication',
    'Event/tradeshow',
    'Other',
];
const INDUSTRY_OPTIONS = [
    'Quality Assurance',
    'Video Operations',
    'Video Engineering',
    'Customer Care',
    'Marketing/Product',
    'Ads',
    'Other',
];

const propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    submitButtonLabel: PropTypes.string,
    excludedFields: PropTypes.arrayOf(PropTypes.string.isRequired),
    isModal: PropTypes.bool,
    isColumnLayout: PropTypes.bool,
    onCancel: PropTypes.func,
    isDebug: PropTypes.bool,
};

const defaultProps = {
    submitButtonLabel: 'Submit',
    excludedFields: [],
    isModal: false,
    isColumnLayout: false,
    onCancel: null,
    isDebug: false,
};

const SalesforceForm = ({ type, title, submitButtonLabel, excludedFields, isModal, isColumnLayout, onCancel, isDebug }) => {
    const { openModal, sourceButton, customMessage } = useContext(ModalContext);
    const { pathname } = useLocation();
    const modalButtonId = `${type}${sourceButton ? `-${sourceButton}` : ''}`;
    const formId = `${modalButtonId} ${pathname.replace(/^\/|\/$/gu, '') || 'home'}`;

    const [ formValues, setFormValues ] = useState({
        [ FIRST_NAME ]: '',
        [ LAST_NAME ]: '',
        [ EMAIL ]: '',
        [ COMPANY ]: '',
        [ COUNTRY_CODE ]: '',
        [ INDUSTRY ]: '',
        [ ACKNOWLEDGEMENT ]: false,
    });
    const [ captchaToken, setCaptchaToken ] = useState(false);

    const { errors, hasError, addError, removeError } = useFormErrors();

    const isProspectForm = type === NEWSLETTER;

    const onNewsletterSubscription = useCallback(isSuccess => {
        openModal({
            modalType: SUCCESS,
            successModalVariant: isSuccess ? YOUR_IN : ERROR_NEWSLETTER,
        });
    }, [ openModal ]);

    const {
        onSubscribeToNewsletter,
        resetState: resetApiError,
        isLoading,
        hasError: hasApiError,
    } = useSubscribeToNewsletter(onNewsletterSubscription);

    const validateRequiredField = useCallback((key, value, errorMessage = 'This field is required.') => {
        if (value.length <= 0 || (typeof value === 'boolean' && !value)) {
            addError(key, errorMessage);
            return true;
        }

        removeError(key);
        return false;
    }, [ addError, removeError ]);

    const validate = useCallback((name, value) => {
        // If we're not using/displaying a specific field in the form, no need to check its value
        if (excludedFields.includes(name)) {
            return;
        }

        switch (name) {
            case FIRST_NAME:
            case LAST_NAME:
            case COMPANY:
            case COUNTRY_CODE:
                validateRequiredField(name, value);
                break;
            case INDUSTRY:
                // The field is not required on the Newsletter form (Prospect) but is on the Contact Us
                if (!isProspectForm) {
                    validateRequiredField(name, value);
                }
                break;
            case EMAIL:
                if (validateRequiredField(name, value)) {
                    break;
                }

                if (isEmailValid(value)) {
                    removeError(EMAIL);
                } else {
                    addError(EMAIL, 'This field is required. Please enter a valid email associated with your business.');
                }
                break;
            case ACKNOWLEDGEMENT:
                if (value) {
                    removeError(ACKNOWLEDGEMENT);
                } else {
                    addError(ACKNOWLEDGEMENT, 'Acknowledgement is required. Please check the box.');
                }
                break;

            default:
                break;
        }
    }, [ addError, removeError, validateRequiredField, excludedFields, isProspectForm ]);

    const onHandleSubmit = useCallback(values => event => {
        validate(FIRST_NAME, values[ FIRST_NAME ]);
        validate(LAST_NAME, values[ LAST_NAME ]);
        validate(EMAIL, values[ EMAIL ]);
        validate(COMPANY, values[ COMPANY ]);
        validate(COUNTRY_CODE, values[ COUNTRY_CODE ]);
        validate(ACKNOWLEDGEMENT, values[ ACKNOWLEDGEMENT ]);

        // Captcha must be completed
        if (!captchaToken) {
            event.preventDefault();
            return;
        }

        // Prevent submiting a form containing errors
        if (hasError) {
            event.preventDefault();
            const wrongInput = document.querySelector(`#${Object.keys(errors)?.at(0)}`);

            // If the form is in the main content (not in a modal), we scroll the page to focus the error field
            if (wrongInput && !isModal) {
                const headerSize = 64;
                window.scrollTo({
                    top: wrongInput.getBoundingClientRect().top + window.scrollY - headerSize - 20,
                    behavior: 'smooth'
                });
            }
        }

        // For a Prospect Form using a back-end call,
        // we don't want to post to the action and rather use our function
        if (isProspectForm) {
            event.preventDefault();
            onSubscribeToNewsletter(values, captchaToken);
        }
    }, [ validate, hasError, errors, captchaToken, isModal, isProspectForm, onSubscribeToNewsletter ]);

    const onCaptchaLoaded = useCallback(valid => {
        setCaptchaToken(valid);
    }, []);

    const onHandleChange = useCallback(event => {
        if (hasApiError) {
            resetApiError();
        }

        const {
            value,
            id: fieldId,
            type: fieldType,
            checked,
        } = event.target;

        const valueParsed = fieldType === 'checkbox' ? checked : value;
        setFormValues(previousState => ({
            ...previousState,
            [ fieldId ]: valueParsed
        }));

        validate(fieldId, valueParsed);
    }, [ hasApiError, resetApiError, validate ]);

    return (
        <form
            action={isProspectForm ? '#' : SALESFORCE_ADD_LEAD_FORM_ACTION}
            onSubmit={onHandleSubmit(formValues)}
            method='POST'
            className={isColumnLayout ? styles.column : null}
        >
            {!isProspectForm && <FormHiddenFields formId={formId} isDebug={isDebug} />}

            <div className={styles.innerFormContainer}>
                <div className={styles.topContainer}>
                    <h3 className={styles.aboutYouH3}>{title}</h3>
                </div>

                <div className={styles.rowContainer}>
                    {!excludedFields.includes(FIRST_NAME) && (
                        <FormInput
                            id={FIRST_NAME}
                            label='First name'
                            value={formValues[ FIRST_NAME ]}
                            error={errors[ FIRST_NAME ]}
                            onChange={onHandleChange}
                            isRequired
                            maxLength={40}
                            autoComplete='given-name'
                        />
                    )}
                    {!excludedFields.includes(LAST_NAME) && (
                        <FormInput
                            id={LAST_NAME}
                            label='Last name'
                            value={formValues[ LAST_NAME ]}
                            error={errors[ LAST_NAME ]}
                            onChange={onHandleChange}
                            isRequired
                            maxLength={80}
                            autoComplete='family-name'
                        />
                    )}
                </div>
                <div className={styles.rowContainer}>
                    {!excludedFields.includes(EMAIL) && (
                        <FormInput
                            id={EMAIL}
                            label='Business Email'
                            value={formValues[ EMAIL ]}
                            error={errors[ EMAIL ]}
                            onChange={onHandleChange}
                            isRequired
                            maxLength={80}
                            type='email'
                        />
                    )}
                    {!excludedFields.includes(COUNTRY_CODE) && (
                        <FormSelect
                            id={COUNTRY_CODE}
                            label='Country'
                            options={COUNTRY_CODES_LIST}
                            value={formValues[ COUNTRY_CODE ]}
                            error={errors[ COUNTRY_CODE ]}
                            onChange={onHandleChange}
                            isRequired
                            autoComplete='country'
                        />
                    )}
                </div>
                <div className={styles.rowContainer}>
                    {!excludedFields.includes(COMPANY) && (
                        <FormInput
                            id={COMPANY}
                            label='Company'
                            value={formValues[ COMPANY ]}
                            error={errors[ COMPANY ]}
                            onChange={onHandleChange}
                            isRequired
                            maxLength={50}
                        />
                    )}
                    {!excludedFields.includes(INDUSTRY) && (
                        <FormSelect
                            id={INDUSTRY}
                            label='Function'
                            title='Function'
                            options={INDUSTRY_OPTIONS}
                            value={formValues[ INDUSTRY ]}
                            error={errors[ INDUSTRY ]}
                            onChange={onHandleChange}
                            isRequired={!isProspectForm}
                        />
                    )}
                </div>
                <div className={styles.rowContainer}>
                    {!excludedFields.includes(HEARD_ABOUT_US) && (
                        <FormSelect
                            id={HEARD_ABOUT_US}
                            label='How did you hear about us?'
                            title='Acquisition Channel'
                            options={HEARD_ABOUT_US_OPTIONS}
                        />
                    )}
                    {!excludedFields.includes(PHONE) && (
                        <FormInput
                            id={PHONE}
                            label='Phone'
                            maxLength={40}
                            type='tel'
                        />
                    )}
                </div>
                <div className={styles.singleRowContainer}>
                    {!excludedFields.includes(MESSAGE) && <FormTextArea id={MESSAGE} label='Message' defaultValue={customMessage} />}
                </div>
                <div className={styles.singleRowContainer}>
                    {!excludedFields.includes(MARKETING_EMAILS) && (
                        <FormCheckbox
                            id={MARKETING_EMAILS}
                            data-gtm-id={`${NEWSLETTER_CHECKBOX}-${type}`}
                        >
                            Yes, I agree to receive marketing emails from Witbe about their latest updates.
                        </FormCheckbox>
                    )}
                    {!excludedFields.includes(ACKNOWLEDGEMENT) && (
                        <FormCheckbox
                            id={ACKNOWLEDGEMENT}
                            value={formValues[ ACKNOWLEDGEMENT ]}
                            error={errors[ ACKNOWLEDGEMENT ]}
                            onChange={onHandleChange}
                            isRequired
                        >
                            {
                                isProspectForm
                                    ? 'Yes, I agree to receive marketing emails from Witbe about the latest updates. I also acknowledge that my information will be stored and processed in accordance with Witbe\'s '
                                    : 'By checking this box, I agree that the information entered in this form may be used to contact me regarding my inquiry, in accordance with Witbe\'s '
                            }
                            <a href='https://www.witbe.net/policy/' target='_blank' alt='Policy' rel='noreferrer'>privacy policy</a>
                            .
                        </FormCheckbox>
                    )}
                </div>

                <div className={isModal ? styles.buttonsContainer : styles.buttonsContainerCentered}>
                    <FormCaptcha onLoaded={onCaptchaLoaded} isDebug={isDebug} />

                    <div className={styles.buttonWrapper}>
                        {
                            isModal && (
                                <button
                                    onClick={onCancel}
                                    className={`${styles.cancelButton} ${styles.button}`}
                                    type='button'
                                    data-gtm-id={`${CANCEL}-${modalButtonId}`}
                                >
                                    Cancel
                                </button>
                            )
                        }

                        { isProspectForm ? (
                            <StatusButton
                                isLoading={isLoading}
                                isSuccess={false}
                                isDefault={!isLoading}
                                loadingText='Submitting'
                                defaultText='Submit'
                                successText=''
                                className={styles.submitButton}
                                data-gtm-id={`${SUBMIT}-${modalButtonId}`}
                            />
                        ) : (
                            <input
                                type='submit'
                                name='submit'
                                value={submitButtonLabel}
                                id='submitButton'
                                className={styles.submitButton}
                                data-gtm-id={`${SUBMIT}-${modalButtonId}`}
                            />
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

SalesforceForm.propTypes = propTypes;
SalesforceForm.defaultProps = defaultProps;

export default SalesforceForm;
