import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { useTranslation } from 'react-i18next';
import Link from './Link';
import WitbeLogo from '../../../static/assets/svgs/witbe_logo_color_gradient_2023.svg';
import { useOpenContactModal, useViewport } from '../../hooks';
import FooterSubscribe from './FooterSubscribe';
import { MODAL_SOURCE_BUTTONS } from '../../constants/modal';

import styles from './styles/footer.module.scss';

const { FOOTER } = MODAL_SOURCE_BUTTONS;

const propTypes = {
    isEvents: PropTypes.bool,
    isDarkMode: PropTypes.bool,
}

const defaultProps = {
    isEvents: false,
    isDarkMode: false,
}

const Footer = ({ isEvents, isDarkMode }) => {
    const { t } = useTranslation();
    const openContactModal = useOpenContactModal(FOOTER);

    const { width } = useViewport();
    const breakpoint = 500;
    const isMobile = width < breakpoint;

    const eventsStyles = isEvents && isMobile ? styles.footerContentEvents : styles.footerContent;
    const logoStyles = isEvents && isMobile ? styles.footerLogoEvents : styles.footerLogo;

    return (
        <footer id={styles.footer} className={isDarkMode ? styles.darkMode : null}>
            <div id={eventsStyles}>
                <WitbeLogo id={logoStyles} />
                {isEvents && isMobile ? null : <FooterSubscribe id={styles.footerSubscribe} isDarkMode={isDarkMode} />}
                <nav id={styles.footerNav} aria-label={t(`siteMetadata.navigation.primaryLabel`)}>
                    <ul id={styles.footerNavList}>
                        <li className={styles.footerNavListHeader}>{t(`siteMetadata.footer.navigationLinks.productsAndServices`)}</li>
                        {/* <li>
                            <Link to='/technology/'>{t('siteMetadata.footer.navigationLinks.technology')}</Link>
                        </li> */}
                        <li>
                            <Link to='/technology/'>{t('siteMetadata.footer.navigationLinks.technology')}</Link>
                        </li>
                        <li>
                            <Link to='/technology#suite'>{t('siteMetadata.footer.navigationLinks.rec')}</Link>
                        </li>
                        <li>
                            <Link to='/technology#suite'>{t('siteMetadata.footer.navigationLinks.smartgate')}</Link>
                        </li>
                        <li>
                            <Link to='/technology#suite'>{t('siteMetadata.footer.navigationLinks.workbench')}</Link>
                        </li>
                        <li>
                            <Link to='/products/witbox/'>{t('siteMetadata.footer.navigationLinks.witbox')}</Link>
                        </li>
                        <li>
                            <a target='_blank' rel='noreferrer' href='https://witbe.app/'>{t('siteMetadata.footer.navigationLinks.witbeID')}</a>
                        </li>
                    </ul>
                    <ul id={styles.footerNavList}>
                        <li className={styles.footerNavListHeader}>{t('siteMetadata.footer.navigationLinks.company')}</li>
                        <li>
                            <Link target='_blank' to='/about/'>{t('siteMetadata.footer.navigationLinks.about')}</Link>
                        </li>
                        <li>
                            <Link target='_blank' to='/events/'>{t('siteMetadata.footer.navigationLinks.events')}</Link>
                        </li>
                        <li>
                            <Link target='_blank' to='/careers/'>{t('siteMetadata.footer.navigationLinks.careers')}</Link>
                        </li>
                        {/* <li>
                            <Link target='_blank' to='/articles/'>{t('siteMetadata.footer.navigationLinks.articles')}</Link>
                        </li> */}
                        <li>
                            <a target='_blank' href='http://alwit.net/' rel='noopener noreferrer'>{t('siteMetadata.footer.navigationLinks.investors')}</a>
                        </li>
                        <li>
                            <Link to='/policy/'>{t('siteMetadata.footer.navigationLinks.privacyPolicy')}</Link>
                        </li>
                        <li>
                            <Link to='/locations/'>{t('siteMetadata.footer.navigationLinks.locations')}</Link>
                        </li>
                        <li>
                            <Link to={null} external onClick={openContactModal}>{t('siteMetadata.footer.navigationLinks.contact')}</Link>
                        </li>
                    </ul>
                </nav>
                <nav id={styles.footerSocials} aria-label={t('siteMetadata.navigation.socialsLabel')}>
                    <ul id={styles.footerSocialsList}>
                        <li>
                            <a target='_blank' href='https://www.Linkedin.com/company/witbe/' aria-label='Witbe LinkedIn Page' rel='noopener noreferrer'>
                                <FontAwesomeIcon icon={faLinkedinIn} className={styles.icon} />
                                {/* <FontAwesomeIcon icon={['fab', 'linkedin-in']} className={styles.icon} /> */}
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href='https://www.facebook.com/witbe/' aria-label='Witbe Facebook Page' rel='noopener noreferrer'>
                                <FontAwesomeIcon icon={faFacebookF} className={styles.icon} />
                                {/* <FontAwesomeIcon icon={['fab', 'facebook-f']} className={styles.icon} /> */}
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href="https://twitter.com/witbe" aria-label='Witbe Twitter Page' rel='noopener noreferrer'>
                                <FontAwesomeIcon icon={faXTwitter} className={styles.icon} />
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href="https://www.instagram.com/witbe/" aria-label='Witbe Instagram Page' rel='noopener noreferrer'>
                                <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href="https://www.youtube.com/c/WitbeOfficial" aria-label='Witbe Youtube Page' rel='noopener noreferrer'>
                                <FontAwesomeIcon icon={faYoutube} className={styles.icon} />
                            </a>
                        </li>
                    </ul>
                </nav>
                <p id={styles.footerCopyright}>{t('siteMetadata.footer.copyright')}</p>
                <nav id={styles.footerLegal} aria-label={t('siteMetadata.navigation.legalLabel')}>
                    <ul id={styles.footerLegalList}>
                        <li>
                            <Link to='/sitemap/'>{t('siteMetadata.footer.navigationLinks.siteMap')}</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </footer>
    );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
