import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { ModalContext } from '../../../context';
import { GTM_KEYS } from '../../../constants/googleTagManagerKeys';
import { useGenericModalAnimation } from '../../../hooks';

import styles from './generic-modal.module.scss';

const { FORM: { LEAVE, CLICK_OUTSIDE } } = GTM_KEYS;

const propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf([ 'light', 'dark' ]),
};

const GenericModal = ({ children, onClose, isOpen, variant }) => {
    const { modalId, resetModal } = useContext(ModalContext);
    const { isClosing, isClosed } = useGenericModalAnimation(isOpen, resetModal);

    if (isClosed) {
        return null;
    }

    return (
        <div className={classNames(styles.modalOverlay, { [ styles.closing ]: isClosing })}>
            <div
                className={
                    classNames(styles.modal, {
                        [ styles.modalDark ]: variant === 'dark',
                    })
                }
            >
                <button
                    type='button'
                    className={styles.closeButton}
                    onClick={onClose}
                    data-gtm-id={`${LEAVE}-${modalId}`}
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{ fontSize: '25px' }}
                    />
                </button>
                <div className={styles.modalContent}>
                    {children}
                </div>
            </div>
            <div
                className={styles.backdrop}
                onClick={onClose}
                data-gtm-id={`${CLICK_OUTSIDE}-${modalId}`}
            />
        </div>
    );
};

GenericModal.propTypes = propTypes;
GenericModal.defaultProps = {
    variant: 'light',
};

export default GenericModal;
