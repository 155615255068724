import React, { Fragment, useMemo } from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

import { SALESFORCE_ORG_ID } from '../../../constants/forms';
import { FORM_SUCCESS_MODAL_VARIANTS, FORM_REDIRECT_PARAMS } from '../../../constants/modal';

const propTypes = {
    formId: PropTypes.string.isRequired,
    isDebug: PropTypes.bool,
};

const defaultProps = {
    isDebug: false,
};

const FormHiddenFields = ({ formId, isDebug }) => {
    const { href } = useLocation();

    const returnUrl = useMemo(() => {
        if (href) {
            const url = new URL(href);
            url.searchParams.set(FORM_REDIRECT_PARAMS.SUCCESS, 'true');
            url.searchParams.set(FORM_REDIRECT_PARAMS.VARIANT, FORM_SUCCESS_MODAL_VARIANTS.DEFAULT);
            return url.toString();
        }

        return href;
    }, [ href ]);

    return (
        <Fragment>
            {isDebug && <input type='hidden' name='debug' value='1' />}
            {isDebug && <input type='hidden' name='debugEmail' value='axm@witbe.team' />}

            <input type='hidden' name='oid' value={SALESFORCE_ORG_ID} />
            <input type='hidden' name='retURL' value={returnUrl} />
            <input type='hidden' name='status' id='status' value='New' />
            <input type='hidden' name='lead_source' id='lead_source' value='Website' />
            {/* Account Name */}
            <input type='hidden' name='company' id='company' value='Witbe Incoming Leads' />
            {/* Lead Type (Inbound / Outbound) */}
            <input type='hidden' name='00NHp00000qF5rY' id='00NHp00000qF5rY' value='Inbound' />
            {/* Lead Seniority */}
            <input type='hidden' name='00NHp00000qF5je' id='00NHp00000qF5je' value='Entry' />
            {/* Import Tag (Custom comment) */}
            <input type='hidden' name='00NHp00000slSGv' id='00NHp00000slSGv' value={formId} />
        </Fragment>
    );
};

FormHiddenFields.propTypes = propTypes;
FormHiddenFields.defaultProps = defaultProps;

export default FormHiddenFields;
