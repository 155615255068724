import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import styles from './status-button.module.scss';

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isDefault: PropTypes.bool.isRequired,
    loadingText: PropTypes.string.isRequired,
    successText: PropTypes.string.isRequired,
    defaultText: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const StatusButton = ({
    isLoading,
    isSuccess,
    isDefault,
    loadingText,
    successText,
    defaultText,
    className,
    ...properties
}) => (
    <button
        className={classNames(className, styles.statusButton)}
        type='submit'
        disabled={isLoading}
        {...properties}
    >
        <span data-active={isLoading}>
            {loadingText}
            <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ marginLeft: '10px' }}
            />
        </span>
        <span data-active={isDefault}>
            {defaultText}
        </span>
        <span data-active={isSuccess}>
            {successText}
            <FontAwesomeIcon
                icon={faCheck}
                style={{ marginLeft: '10px' }}
            />
        </span>
    </button>
);

StatusButton.propTypes = propTypes;
StatusButton.defaultProps = defaultProps;

export default StatusButton;
