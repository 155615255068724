/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '../../context';
import { GTM_KEYS, MODAL_TYPES, MODAL_SOURCE_BUTTONS } from '../../constants';

import styles from './styles/subscribe.module.scss';
import subscribeStyles from './styles/subscribeForm.module.scss';

const { NEWSLETTER } = MODAL_TYPES;
const { FOOTER } = MODAL_SOURCE_BUTTONS;
const { MODAL: { NEWSLETTER_BUTTON } } = GTM_KEYS;

const propTypes = {
    isDarkMode: PropTypes.bool,
}

const defaultProps = {
    isDarkMode: false,
}

const FooterSubscribe = ({ isDarkMode }) => {
    const { t } = useTranslation();

    const { openModal } = useContext(ModalContext);
    const openNewsletterModal = useCallback(
        () => openModal({ modalType: NEWSLETTER, sourceButton: FOOTER }),
        [ openModal ],
    );

    return (
        <section id={styles.subscribeSection} className={isDarkMode ? styles.darkMode : null}>
            <h3 className={styles.newsHeaderFooter}>Stay up-to-date with our newsletter</h3>
            <div>
                <button
                    className={`${styles.whiteButtonFooter} ${subscribeStyles.buyButton}`}
                    onClick={openNewsletterModal}
                    type='button'
                    data-gtm-id={`${NEWSLETTER_BUTTON}-${FOOTER}`}
                >
                    {t('siteMetadata.footer.subscribe.title5')}
                </button>
            </div>
        </section>
    );
}

FooterSubscribe.propTypes = propTypes;
FooterSubscribe.defaultProps = defaultProps;

export default FooterSubscribe;
