import { graphql, useStaticQuery } from 'gatsby';

export const useAttendeesData = attendees => {
    const attendeesImages = useStaticQuery(graphql `
        query AttendeesQuery {
            mvl: file(relativePath : { eq: "events/attendees/mvl.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            mpl: file(relativePath : { eq: "events/attendees/mpl.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            yhi: file(relativePath : { eq: "events/attendees/yhi.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            yca: file(relativePath : { eq: "events/attendees/yca.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            pro: file(relativePath : { eq: "events/attendees/pro.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            gga: file(relativePath : { eq: "events/attendees/gga.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            gri: file(relativePath : { eq: "events/attendees/gri.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            jmp: file(relativePath : { eq: "events/attendees/jmp.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            cyl: file(relativePath : { eq: "events/attendees/cyl.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 875
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            }
        }
        `);

    const attendeesData = [
        {
            id: 0,
            img: attendeesImages.mvl.childImageSharp.gatsbyImageData,
            name: 'Marie-Véronique Lacaze',
            job: 'President & Co-Founder',
            bio: '',
            firstName: 'Marie-Véronique',
            email: '',
            trigram: 'mvl',
            alt: 'Marie-Véronique Lacaze'
        },
        {
            id: 1,
            img: attendeesImages.mpl.childImageSharp.gatsbyImageData,
            name: 'Mathieu Planche',
            job: 'Chief Executive Officer',
            bio: '',
            firstName: 'Mathieu',
            email: '',
            trigram: 'mpl',
            alt: 'Mathieu Planche'
        },
        {
            id: 2,
            img: attendeesImages.yhi.childImageSharp.gatsbyImageData,
            name: 'Yoann Hinard',
            job: 'Chief Operations Officer',
            bio: '',
            firstName: 'Yoann',
            email: '',
            trigram: 'yhi',
            alt: 'Yoann Hinard'
        },
        {
            id: 3,
            img: attendeesImages.yca.childImageSharp.gatsbyImageData,
            name: 'Yann Caron',
            job: 'VP Sales | North America',
            bio: '',
            firstName: 'Yann',
            email: '',
            trigram: 'yca',
            alt: 'Yann Caron'
        },
        {
            id: 4,
            img: attendeesImages.pro.childImageSharp.gatsbyImageData,
            name: 'Paul Rolland',
            job: 'President & Co-Founder',
            bio: '',
            firstName: 'Paul',
            email: '',
            trigram: 'pro',
            alt: 'Paul Rolland'
        },
        {
            id: 5,
            img: attendeesImages.gga.childImageSharp.gatsbyImageData,
            name: 'Guillaume Galindo',
            job: 'VP Sales | Central and West America',
            bio: '',
            firstName: 'Guillaume',
            email: '',
            trigram: 'gga',
            alt: 'Guillaume Galindo'
        },
        {
            id: 6,
            img: attendeesImages.gri.childImageSharp.gatsbyImageData,
            name: 'Gilles Richard',
            job: 'VP Sales | EMEA and APAC',
            bio: '',
            firstName: 'Gilles',
            email: '',
            trigram: 'gri',
            alt: 'Gilles Richard'
        },
        {
            id: 7,
            img: attendeesImages.jmp.childImageSharp.gatsbyImageData,
            name: 'Jean-Michel Planche',
            job: 'VP Strategy & Co-Founder',
            bio: '',
            firstName: 'Jean-Michel',
            email: '',
            trigram: 'jmp',
            alt: 'Jean-Michel Planche'
        },
        {
            id: 8,
            img: attendeesImages.cyl.childImageSharp.gatsbyImageData,
            name: 'Cyril Ledain',
            job: 'Operations Director | APAC',
            bio: '',
            firstName: 'Cyril',
            email: '',
            trigram: 'cyl',
            alt: 'Cyril Ledain'
        }
    ];

    const attendeesAttending = attendeesData.filter(
        attendee => attendees.includes(attendee.trigram));

    return attendeesAttending;
};
